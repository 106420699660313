import React from 'react';
import { Col, Row, Toast } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Breadcrumb from '../../components/Breadcrumb';
import FleetSetupTabs from '../../components/FleetSetupTabs';
import { fetchMonitoredCount } from '../DashBoard/actions';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import { fetchFleetsList } from '../FleetView/actions';
import { fleetsListDataSelector } from '../FleetView/selectors';
import {
  clearSaveFleetResponce,
  clearSaveSiteResponce,
  getFleetData,
  getPrdtInfoSetupValues,
  getPrdtSetupValues,
  getSetupFleetList,
  getSetupSidemenuList,
  getSetupSiteList,
  getUsedFleetNameList,
  getValidateFleetDataPath,
  getValidateFleetDataPathSuccess,
  getVehicleSetup,
  saveFleet,
  saveSite,
  saveVehicleSetup,
  saveproductInfoSetup,
  saveproductSetup,
} from './actions';
import messages from './messages';
import {
  fleetDataPathValidateSelector,
  fleetDataSelector,
  loadingFleetDataPathValidateSelector,
  loadingFleetDataSelector,
  loadingPrdtInfoSetupValuesSelector,
  loadingPrdtSetupValuesSelector,
  loadingSetupFleetListSelector,
  loadingSetupSidemenuListSelector,
  loadingSetupSiteListSelector,
  loadingUsedFleetNameListSelector,
  loadingVehicleSetupSelector,
  loadingVehicleSetupValuesSelector,
  loadingproductInfoSetupSelector,
  loadingproductSetupSelector,
  loadingsavefleetSelector,
  loadingsavesiteSelector,
  prdtInfoSetupValuesSelector,
  prdtSetupValuesSelector,
  saveProductInfoSetupResponceSelector,
  saveProductSetupResponceSelector,
  saveVehicleSetupResponceSelector,
  savefleetresponceSelector,
  savesiteresponceSelector,
  setupFleetListSelector,
  setupSidemenuListSelector,
  setupSiteListSelector,
  usedFleetNameListSelector,
  vehicleSetupValuesSelector,
} from './selectors';
import './styles.scss';

import moment from 'moment';
import { CheckMarkCircle, HomeIcon } from '../../assets/images';

moment.locale('en');

export class FleetSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      activeTabName: '',
      sohStressActiveTab: '',
      selectedTab: 'Fleetmgmt',
      selectedProdSetupUsgsTab: 'prodsetupusgtemp',
      showApiresnotification: false,
      fleetToSearch: 'All',
      siteFormVisible: true,
      fleetFormVisible: false,
      selectedSite: '',
      selectedFleet: 'WINK_ARRIVA',
      siteFormValues: {
        siteName: '',
        siteID: '',
        siteOpname: '',
        siteOpID: '',
        siteContactP: '',
        jobFunContct: '',
        siteEmail: '',
        sitePhone: '',
        sitelocN: '',
        siteAddress: [],
        displaySiteName: '',
      },
      fleetFormValues: {
        webPortalLive: false,
        dataApiLive: false,
        isDraft: false,
        displayFleetName: '',
        isUpdate: false,
        fleetName: '',
        newFleetName: '',
        fleetId: '',
        fleetConType: '',
        username: '',
        password: '',
        platform: '',
        connectivityKeyPath: '',
        connectivityKeyPathList: [],
        fleetDPath: '',
        fleetLinkDb: '',
        fleetTotalVehicle: '',
        fleetServices: ['ESS RCM'],
        Isplatform2: false,
        fleetVname: '',
        fleetVtype: '',
        fleetPtype: '',
        fleetPAvgthru: '',
        fleetFsav: '',
        designedThroughput: '',
        maxThroughput: '',
        firstLineStartColor: '#f5f5f5',
        firstLineEndColor: '#000000',
        secondLineStartColor: '#ffda99',
        secondLineEndColor: '#ffa200',
        slaStartDate: new Date(moment().subtract(1, 'days').startOf('day')),
        accessExpirationDetailList: [],
      },
      vehicleSetupFormValues: {
        fleetName: 'WINK_ARRIVA',
        vehiclePrefix: 'Vehicle',
        vehType: '',
        relThres: '',
        utiThres: '',
      },
      productSetupFormValues: {
        fleetName: 'WINK_ARRIVA',
        relThresNotRelStat: '',
        utilThresAbNUtilStat: '',
        tempThresStrt: '',
        tempThresEnd: '',
        socThresStrt: '',
        socThresEnd: '',
        cylThres: '',
        crateThres: '',
        dSpan: '',
        capacityAfterDesignedLifetime: '',
        throughputLifetime: '',
        designBudget: [],
        sohModelOutput: '',
        essType: '',
        vehicleType: '',
        productType: 'ESS',
        essArticleNumber: '',
        ratedCapacity: '',
        ratedVoltage: '',
        ratedEnergy: '',
        maxCurrent: '',
        packPrefix: 'STRING',
        numberOfBatterySystem: '1',
        numberOfStrings: '1',
        converterPrefix: 'TC',
        converterEnable: false,
      },
      addSiteActive: false,
      addFleetActive: false,
      editSite: false,
      editFleet: false,
    };
  }

  componentDidMount() {
    // await this.props.clearSaveFleetResponce();
    this.props.monitoredCountData === null && this.props.getMonitoredCount();
    // this.props.setupSidemenuList === null &&
    this.props.getSetupSidemenuList();
    this.props.getFleetData();
    this.props.fleetsListData === null &&
      this.props.getFleetsList('All', 'All', 'All', true, true, true);
    // this.props.setupSiteList === null && this.props.getSetupSiteList();
    this.props.getPrdtSetupValues('WINK_ARRIVA');
    this.props.getPrdtInfoSetupValues('WINK_ARRIVA');
    this.props.getVehicleSetup('WINK_ARRIVA');
    this.props.getSetupFleetList('WINK_ARRIVA');
    this.props.getUsedFleetNameList();
  }

  UNSAFE_componentWillReceiveProps(newProps, prevState) {
    if (
      newProps.savesiteresponce !== null &&
      newProps.savesiteresponce.length > 0
    ) {
      this.setState({
        showApiresnotification: true,
      });
    }

    if (
      newProps.saveProductSetupResponce !== null &&
      newProps.saveProductSetupResponce.length > 0 &&
      newProps.saveProductInfoSetupResponce !== null &&
      newProps.saveProductInfoSetupResponce.length > 0 &&
      newProps.saveVehicleSetupResponce !== null &&
      newProps.saveVehicleSetupResponce.length > 0 &&
      newProps.savefleetresponce !== null &&
      newProps.savefleetresponce.length > 0
    ) {
      // this.setState({ showApiresnotification: true });
      this.setState({
        showApiresnotification: true,
      });
    }

    if (
      newProps.setupSidemenuList !== null &&
      newProps.setupSidemenuList !== this.props.setupSidemenuList &&
      newProps.setupSidemenuList.length > 0
    ) {
      // sessionStorage.setItem("siteId", newProps.setupSidemenuList[0].siteId);
      this.setState({
        siteFormValues: {
          ...this.state.siteFormValues,
          siteID: newProps.setupSidemenuList[0].siteId,
        },
      });
    }

    if (
      newProps.setupSiteList !== null &&
      newProps.setupSiteList !== this.props.setupSiteList &&
      newProps.setupSiteList.length > 0
    ) {
      sessionStorage.setItem('siteId', newProps.setupSiteList[0].siteId);
      this.setState({
        siteFormValues: {
          ...this.state.siteFormValues,
          siteName: newProps.setupSiteList[0].siteName,
          siteID: newProps.setupSiteList[0].siteId,
          siteOpname: newProps.setupSiteList[0].operatorName,
          siteOpID: newProps.setupSiteList[0].operatorID,
          siteContactP: newProps.setupSiteList[0].nameOfContact,
          jobFunContct: newProps.setupSiteList[0].jobFunctionOfContact,
          siteEmail: newProps.setupSiteList[0].emailAddress,
          sitePhone: newProps.setupSiteList[0].phoneNumber,
          sitelocN: newProps.setupSiteList[0].siteLocation,
          siteAddress: newProps.setupSiteList[0].siteMapAddress,
          displaySiteName: newProps.setupSiteList[0].displaySiteName,
        },
      });
    }

    if (
      newProps.setupFleetList !== null &&
      newProps.setupFleetList !== this.props.setupFleetList &&
      newProps.setupFleetList.length > 0
    ) {
      sessionStorage.setItem('fleetId', newProps.setupFleetList[0].fleetId);
      this.setState(
        {
          fleetFormValues: {
            ...this.state.fleetFormValues,
            webPortalLive: newProps.setupFleetList[0].webPortalLive,
            dataApiLive: newProps.setupFleetList[0].dataApiLive,
            isDraft: newProps.setupFleetList[0].isDraft,
            isUpdate: true,
            fleetName: newProps.setupFleetList[0].fleetName,
            fleetId: newProps.setupFleetList[0].fleetId,
            fleetConType: newProps.setupFleetList[0].connectivityType,
            username: newProps.setupFleetList[0].userName,
            password: newProps.setupFleetList[0].password,
            connectivityKeyPath: newProps.setupFleetList[0].connectivityKeyPath,
            fleetDPath: newProps.setupFleetList[0].fleetDataPath,
            displayFleetName: newProps.setupFleetList[0].displayFleetName,
            platform: newProps.setupFleetList[0].platform,
            designedThroughput: newProps.setupFleetList[0].designedThroughput,
            maxThroughput: newProps.setupFleetList[0].maxThroughput,
            firstLineStartColor: newProps.setupFleetList[0].firstLineStartColor,
            firstLineEndColor: newProps.setupFleetList[0].firstLineEndColor,
            secondLineStartColor:
              newProps.setupFleetList[0].secondLineStartColor,
            secondLineEndColor: newProps.setupFleetList[0].secondLineEndColor,
            slaStartDate: newProps.setupFleetList[0].slaStartDate,
            accessExpirationDetailList:
              newProps.setupFleetList[0].externalEntitlements,
          },
        }
        // () =>
        //   this.getConnectivityKeyPathList(
        //     newProps.setupFleetList[0].connectivityType
        //   )
      );
    }

    if (
      newProps.vehicleSetupValues !== null &&
      newProps.vehicleSetupValues !== this.props.vehicleSetupValues &&
      newProps.vehicleSetupValues.length > 0
    ) {
      this.setState({
        vehicleSetupFormValues: {
          ...this.state.vehicleSetupFormValues,
          vehType: newProps.vehicleSetupValues[0].vehicleType,
          vehiclePrefix: newProps.vehicleSetupValues[0].vehiclePrefix,
          relThres: newProps.vehicleSetupValues[0].relibilityThreshold,
          utiThres: newProps.vehicleSetupValues[0].utilizationThreshold,
        },
      });
    }

    if (
      newProps.prdtSetupValues !== null &&
      newProps.prdtSetupValues !== this.props.prdtSetupValues &&
      newProps.prdtSetupValues.length > 0
    ) {
      this.setState({
        activeTabName: newProps.prdtSetupValues[0].designSettings[0].tabName,
        sohStressActiveTab:
          newProps.prdtSetupValues[0].designSettings[0].sohTabName,
        productSetupFormValues: {
          ...this.state.productSetupFormValues,
          dSpan: newProps.prdtSetupValues[0].lifeTimeInYears,
          capacityAfterDesignedLifetime:
            newProps.prdtSetupValues[0].capacityAfterDesignedLifetime,
          throughputLifetime: newProps.prdtSetupValues[0].throughputLifetime,
          designBudget: newProps.prdtSetupValues[0].designSettings,
        },
      });
    }

    if (
      newProps.prdtInfoSetupValues !== null &&
      newProps.prdtInfoSetupValues !== this.props.prdtInfoSetupValues &&
      newProps.prdtInfoSetupValues.length > 0
    ) {
      // this.setState({ showApiresnotification: true });
      this.setState({
        productSetupFormValues: {
          ...this.state.productSetupFormValues,
          batteryPrefix: newProps.prdtInfoSetupValues[0].batteryPrefix,
          essArticleNumber: newProps.prdtInfoSetupValues[0].essArticleNumber,
          packPrefix: newProps.prdtInfoSetupValues[0].packPrefix,
          numberOfBatterySystem:
            newProps.prdtInfoSetupValues[0].numberOfBatterySystem,
          numberOfStrings: newProps.prdtInfoSetupValues[0].numberOfStrings,
          ratedCapacity: newProps.prdtInfoSetupValues[0].ratedCapacity,
          ratedVoltage: newProps.prdtInfoSetupValues[0].ratedVoltage,
          ratedEnergy: newProps.prdtInfoSetupValues[0].ratedEnergy,
          maxCurrent: newProps.prdtInfoSetupValues[0].maxCurrent,
          relThresNotRelStat:
            newProps.prdtInfoSetupValues[0].reliabilityThresholdNotRelibleState,
          utilThresAbNUtilStat:
            newProps.prdtInfoSetupValues[0]
              .utilizationThresholdAboveNormalUtilizationState,
          tempThresStrt:
            newProps.prdtInfoSetupValues[0].minThresholdTempearture,
          tempThresEnd: newProps.prdtInfoSetupValues[0].maxThresholdTempearture,
          socThresStrt: newProps.prdtInfoSetupValues[0].minThresholdSOC,
          socThresEnd: newProps.prdtInfoSetupValues[0].maxThresholdSOC,
          cylThres: newProps.prdtInfoSetupValues[0].maxThresholdCycles,
          crateThres: newProps.prdtInfoSetupValues[0].maxThresholdCRate,
          sohModelOutput: newProps.prdtInfoSetupValues[0].sohModelOutput,
          converterPrefix: newProps.prdtInfoSetupValues[0].converterPrefix,
          converterEnable: newProps.prdtInfoSetupValues[0].converterEnable,
        },
      });
    }
  }

  setColor = (id, color) => {
    const updatedData = [...this.state.productSetupFormValues.designBudget];
    updatedData.forEach(designSetting => {
      designSetting.data.forEach(item => {
        if (`${designSetting.tabName}_${item.bin}` === id) {
          item.color = color;
        }
      });
    });
    this.setState({
      ...this.state.productSetupFormValues,
      designBudget: updatedData,
    });
  };

  handleSwitchChange = (showDesignBudgetCurve, id) => {
    const updatedData = [...this.state.productSetupFormValues.designBudget];
    updatedData.forEach(designSetting => {
      if (designSetting.tabName === id) {
        designSetting.showDesignBudgetCurve = showDesignBudgetCurve;
      }
    });
    this.setState({
      ...this.state.productSetupFormValues,
      designBudget: updatedData,
    });
  };

  handleAddRow = tabName => {
    const { designBudget } = this.state.productSetupFormValues;

    const updatedData = [...designBudget];
    const tabData = updatedData.find(
      tab => tab.sohTabName === tabName
    ).extraStandardSOHConditionData;
    const indexPosition = tabData.length - 1;
    const newRow = {
      min: '',
      minCondition: '≤',
      conditionLabel: tabName,
      maxCondition: '≤',
      max: '',
      penalty: '',
    };
    tabData.splice(indexPosition, 0, newRow); // Insert new row after the first row
    this.setState({
      ...this.state.productSetupFormValues,
      designBudget: updatedData,
    });
  };

  handleInputChange = (event, rowIndex, inputName, activeTab) => {
    const { designBudget } = this.state.productSetupFormValues;
    const { value } = event.target;
    const updatedData = [...designBudget];
    updatedData.find(
      tab => tab.sohTabName === activeTab
    ).extraStandardSOHConditionData[rowIndex][inputName] = value;
    this.setState({
      ...this.state.productSetupFormValues,
      designBudget: updatedData,
    });
  };

  handleRemoveRow = (tabName, rowIndex) => {
    const { designBudget } = this.state.productSetupFormValues;

    const updatedData = [...designBudget];
    const tabData = updatedData.find(
      tab => tab.sohTabName === tabName
    ).extraStandardSOHConditionData;
    tabData.splice(rowIndex, 1);
    this.setState({
      ...this.state.productSetupFormValues,
      designBudget: updatedData,
    });
  };

  handleChange = (e, id) => {
    const { value } = e.target;

    // this.setState(prevState => {
    //   const updatedData = { ...prevState.data };
    const updatedData = [...this.state.productSetupFormValues.designBudget];
    updatedData.forEach(designSetting => {
      designSetting.data.forEach(item => {
        if (`${designSetting.tabName}_${item.bin}` === id) {
          item.value = parseFloat(value);
        }
      });
    });
    this.setState({
      ...this.state.productSetupFormValues,
      designBudget: updatedData,
    });
  };

  onPlaceSelected = place => {
    if (place !== undefined) {
      this.setState({
        siteFormValues: {
          ...this.state.siteFormValues,
          // sitelocN: place.address_components[0].long_name,
          sitelocN: place.formatted_address,
          siteAddress: [
            {
              lat: place.geometry.location.lat,
              lng: place.geometry.location.lng,
            },
          ],
        },
      });
    }
  };

  onFleetSetupTabClick = tab => {
    this.setState({ selectedTab: tab });
    if (tab === 'Fleetmgmt') {
      //
    } else if (tab === 'Usermgmt') {
      //
    }
  };

  handleFleetSelect = selectedOptions => {
    if (selectedOptions.length) {
      this.props.getFleetsList(
        'All',
        'All',
        selectedOptions[0].nm,
        true,
        true,
        true
      );
    }
  };

  handleSearchClear = () => {
    this.props.getFleetsList('All', 'All', 'All', true, true, true);
  };

  handleSiteClick = async selectedSiteData => {
    await this.props.clearSaveFleetResponce();
    if (selectedSiteData !== null) {
      this.setState(
        {
          siteFormVisible: true,
          fleetFormVisible: false,
          selectedSite: selectedSiteData.siteName,
          selectedFleet: null,
          addSiteActive: false,
          addFleetActive: false,
          editSite: true,
        },
        () => {
          this.props.clearSaveFleetResponce();
          this.props.getSetupSiteList(selectedSiteData.siteName);
        }
      );
    } else {
      this.setState({
        siteFormVisible: true,
        fleetFormVisible: false,
        selectedSite: selectedSiteData,
        selectedFleet: null,
        addSiteActive: true,
        addFleetActive: false,
        editSite: false,
        siteFormValues: {
          ...this.state.siteFormValues,
          siteName: '',
          siteID: this.props.setupSidemenuList[0].siteId,
          siteOpname: '',
          siteOpID: '',
          siteContactP: '',
          jobFunContct: '',
          siteEmail: '',
          sitePhone: '',
          sitelocN: '',
          siteAddress: [],
          displaySiteName: '',
        },
      });
    }
  };

  handleFleetClick = (selectedFleet, selectedFleetData) => {
    if (selectedFleet !== null) {
      this.setState({
        siteFormVisible: false,
        fleetFormVisible: true,
        selectedFleet,
        addSiteActive: false,
        addFleetActive: false,
        editFleet: true,
        fleetFormValues: {
          ...this.state.fleetFormValues,
          webPortalLive: selectedFleetData.webPortalLive,
          dataApiLive: selectedFleetData.dataApiLive,
          isDraft: selectedFleetData.isDraft,
          fleetName: selectedFleetData.fleetName,
          fleetTotalVehicle: selectedFleetData.noOfVehicles,
          fleetServices: selectedFleetData.services,
          fleetConType: selectedFleetData.connectivityType,
          Isplatform2: selectedFleetData.isPlatform2,
          connectivityKeyPath: selectedFleetData.connectivityKeyPath,
          fleetDPath: selectedFleetData.fleetDataPath,
          fleetLinkDb: selectedFleetData.linkToTractionDatabase,
          fleetVname: selectedFleetData.vehicleName,
          fleetVtype: selectedFleetData.vehicleType,
          fleetPtype: selectedFleetData.productType,
          fleetPAvgthru: selectedFleetData.avgThroughPut,
          fleetFsav: selectedFleetData.fuel,
          displayFleetName: selectedFleetData.displayFleetName,
          platform: selectedFleetData.platform,
          designedThroughput: selectedFleetData.designedThroughput,
          maxThroughput: selectedFleetData.maxThroughput,
          firstLineStartColor: selectedFleetData.firstLineStartColor,
          firstLineEndColor: selectedFleetData.firstLineEndColor,
          secondLineStartColor: selectedFleetData.secondLineStartColor,
          secondLineEndColor: selectedFleetData.secondLineEndColor,
          slaStartDate: selectedFleetData.slaStartDate,
          accessExpirationDetailList: selectedFleetData.externalEntitlements,
        },
      });
    } else {
      this.setState({
        siteFormVisible: false,
        fleetFormVisible: true,
        selectedFleet,
        addSiteActive: false,
        addFleetActive: true,
        editFleet: false,
        fleetFormValues: {
          ...this.state.fleetFormValues,
          webPortalLive: false,
          dataApiLive: false,
          isDraft: false,
          fleetName: '',
          fleetTotalVehicle: '',
          fleetServices: '',
          fleetConType: '',
          Isplatform2: '',
          connectivityKeyPath: '',
          fleetDPath: '',
          fleetLinkDb: '',
          fleetVname: '',
          fleetVtype: '',
          fleetPtype: '',
          fleetPAvgthru: '',
          fleetFsav: '',
          displayFleetName: '',
          platform: '',
          designedThroughput: '',
          maxThroughput: '',
          firstLineStartColor: '',
          firstLineEndColor: '',
          secondLineStartColor: '',
          secondLineEndColor: '',
          slaStartDate: new Date(moment().subtract(1, 'days').startOf('day')),
          accessExpirationDetailList: [],
        },
      });
    }
  };

  handleSiteInputChange = e => {
    if (e !== undefined) {
      this.setState({
        siteFormValues: {
          ...this.state.siteFormValues,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleSiteAddressChange = selectedAddress => {
    if (selectedAddress !== undefined) {
      this.setState({
        siteFormValues: {
          ...this.state.siteFormValues,
          siteAddress: selectedAddress,
        },
      });
    }
  };

  handleSiteSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    await this.props.clearSaveFleetResponce();
    this.props.saveSite(this.state.editSite, this.state.siteFormValues);
  };

  handleSiteReset = async () => {
    await this.props.clearSaveFleetResponce();
    await this.handleSiteClick(
      !this.state.editSite ? null : this.state.siteFormValues
    );
  };

  handleFleetInputChange = e => {
    if (e !== undefined) {
      if (e.target.name === 'fleetConType') {
        this.setState(
          {
            fleetFormValues: {
              ...this.state.fleetFormValues,
              [e.target.name]: e.target.value,
              platform: ' ',
              connectivityKeyPath: '',
            },
          }
          // () => this.getConnectivityKeyPathList(selectedValueTemp)
        );
      }
      if (e.target.name === 'platform')
        this.setState(
          {
            fleetFormValues: {
              ...this.state.fleetFormValues,
              [e.target.name]: e.target.value,
              connectivityKeyPath: '',
            },
          }
          // () => this.getConnectivityKeyPathList(selectedValueTemp)
        );
      else {
        if (e.target.name === 'displayFleetName') {
          this.setState({
            fleetFormValues: {
              ...this.state.fleetFormValues,
              [e.target.name]: e.target.value,
            },
            vehicleSetupFormValues: {
              ...this.state.vehicleSetupFormValues,
              [e.target.name]: e.target.value,
            },
            productSetupFormValues: {
              ...this.state.productSetupFormValues,
              [e.target.name]: e.target.value,
            },
          });
        } else if (
          e.target.name === 'dataApiLive' ||
          e.target.name === 'webPortalLive'
        ) {
          this.setState({
            fleetFormValues: {
              ...this.state.fleetFormValues,
              [e.target.name]: e.target.checked,
            },
          });
        } else {
          this.setState({
            fleetFormValues: {
              ...this.state.fleetFormValues,
              [e.target.name]: e.target.value,
            },
          });
          // Fleet data path validation
          this.props.fleetDataPathValidate === false &&
            this.props.getValidateFleetDataPathSuccess(null);
        }
      }
    }
  };

  handleFleetCheckboxChange = e => {
    if (e !== undefined) {
      this.setState({
        fleetFormValues: {
          ...this.state.fleetFormValues,
          [e.target.name]: e.target.checked,
        },
      });
    }
  };

  handleFleetDropdownChange = (name, value) => {
    if (value !== undefined) {
      this.setState({
        fleetFormValues: {
          ...this.state.fleetFormValues,
          [name]: value,
        },
      });
    }
  };

  handleFleetSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    await this.props.clearSaveFleetResponce();
    this.props.saveFleet(
      this.state.editFleet,
      this.state.selectedSite,
      this.state.fleetFormValues.webPortalLive,
      this.state.fleetFormValues.dataApiLive,
      this.state.fleetFormValues.isDraft,
      this.state.fleetFormValues.fleetName,
      this.state.fleetFormValues.fleetTotalVehicle,
      this.state.fleetFormValues.fleetServices,
      this.state.fleetFormValues.fleetConType,
      this.state.fleetFormValues.Isplatform2,
      this.state.fleetFormValues.connectivityKeyPath,
      this.state.fleetFormValues.fleetDPath,
      this.state.fleetFormValues.fleetLinkDb,
      this.state.fleetFormValues.fleetVname,
      this.state.fleetFormValues.fleetVtype,
      this.state.fleetFormValues.fleetPtype,
      this.state.fleetFormValues.fleetPAvgthru,
      this.state.fleetFormValues.fleetFsav,
      this.state.fleetFormValues.displayFleetName,
      this.state.fleetFormValues.platform,
      this.state.fleetFormValues.designedThroughput,
      this.state.fleetFormValues.maxThroughput,
      this.state.fleetFormValues.firstLineStartColor,
      this.state.fleetFormValues.firstLineEndColor,
      this.state.fleetFormValues.secondLineStartColor,
      this.state.fleetFormValues.secondLineEndColor,
      this.state.fleetFormValues.slaStartDate,
      this.state.fleetFormValues.accessExpirationDetailList
    );
    //  alert(`You submitted:\n\n${JSON.stringify(this.state.fleetFormValues)}`);
    if (this.state.editFleet !== true) {
      this.setState({
        fleetFormValues: {
          webPortalLive: false,
          dataApiLive: false,
          isDraft: false,
          fleetName: '',
          fleetTotalVehicle: '',
          fleetServices: ['ESS RCM'],
          fleetConType: '',
          Isplatform2: false,
          connectivityKeyPath: '',
          fleetDPath: '',
          fleetLinkDb: '',
          fleetVname: '',
          fleetVtype: '',
          fleetPtype: '',
          fleetPAvgthru: '',
          fleetFsav: '',
          displayFleetName: '',
          platform: '',
          designedThroughput: '',
          maxThroughput: '',
          firstLineStartColor: '',
          firstLineEndColor: '',
          secondLineStartColor: '',
          secondLineEndColor: '',
          slaStartDate: new Date(moment().subtract(1, 'days').startOf('day')),
          accessExpirationDetailList: [],
        },
      });
    }
  };

  handleFleetReset = () => {
    this.setState({
      fleetFormValues: {
        webPortalLive: false,
        dataApiLive: false,
        isDraft: false,
        fleetName: '',
        fleetTotalVehicle: '',
        fleetServices: ['ESS RCM'],
        fleetConType: '',
        Isplatform2: false,
        connectivityKeyPath: '',
        fleetDPath: '',
        fleetLinkDb: '',
        fleetVname: '',
        fleetVtype: '',
        fleetPtype: '',
        fleetPAvgthru: '',
        fleetFsav: '',
        displayFleetName: '',
        platform: '',
        designedThroughput: '',
        maxThroughput: '',
        firstLineStartColor: '',
        firstLineEndColor: '',
        secondLineStartColor: '',
        secondLineEndColor: '',
        slaStartDate: new Date(moment().subtract(1, 'days').startOf('day')),
        accessExpirationDetailList: [],
      },
    });
  };

  onProdSetupUsgsTabClick = tab => {
    this.setState({ selectedProdSetupUsgsTab: tab });
  };

  handleProdInputChange = e => {
    if (e !== undefined) {
      if (e.target.name === 'converterEnable') {
        this.setState({
          productSetupFormValues: {
            ...this.state.productSetupFormValues,
            [e.target.name]: e.target.checked,
          },
        });
      } else {
        this.setState({
          productSetupFormValues: {
            ...this.state.productSetupFormValues,
            [e.target.name]: e.target.value,
          },
        });
      }
    }
  };

  handleSohModelInputChange = e => {
    if (e !== undefined) {
      this.setState({
        productSetupFormValues: {
          ...this.state.productSetupFormValues,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  handleVehicleInputChange = e => {
    const tempe = e.target.name;
    if (e !== undefined) {
      this.setState(
        {
          vehicleSetupFormValues: {
            ...this.state.vehicleSetupFormValues,
            [e.target.name]: e.target.value,
          },
        },
        () => {
          if (tempe === 'vehType') {
            const fiteredData = this.props.vehicleSetupValues.filter(
              item =>
                item.vehicleType === this.state.vehicleSetupFormValues.vehType
            );
            if (fiteredData.length > 0) {
              this.setState({
                vehicleSetupFormValues: {
                  ...this.state.vehicleSetupFormValues,
                  vehType: fiteredData[0].vehicleType,
                  vehiclePrefix: fiteredData[0].vehiclePrefix,
                  relThres: fiteredData[0].relibilityThreshold,
                  utiThres: fiteredData[0].utilizationThreshold,
                },
              });
            } else {
              this.setState({
                vehicleSetupFormValues: {
                  ...this.state.vehicleSetupFormValues,
                  vehiclePrefix: '',
                  relThres: '',
                  utiThres: '',
                },
              });
            }
          }
        }
      );
    }
  };

  handleProdSubmit = async e => {
    // Draft handling
    this.setState({
      fleetFormValues: {
        ...this.state.fleetFormValues,
        isDraft: e.nativeEvent.submitter.id === 'draftsave',
      },
    });

    e.preventDefault();
    e.stopPropagation();
    await this.props.clearSaveFleetResponce();
    // alert(JSON.stringify(this.state.productSetupFormValues));
    this.props.saveFleet(this.state.fleetFormValues);
    if (!this.state.fleetFormValues.isUpdate) {
      this.setState({
        ...this.state,
        fleetFormValues: {
          ...this.state.fleetFormValues,
          fleetName: this.state.fleetName,
        },
        vehicleSetupFormValues: {
          ...this.state.vehicleSetupFormValues,
          fleetName: this.state.fleetName,
        },
        productSetupFormValues: {
          ...this.state.productSetupFormValues,
          fleetName: this.state.fleetName,
        },
      });
    }
    this.props.saveproductSetup(this.state.productSetupFormValues);
    this.props.saveproductInfoSetup(this.state.productSetupFormValues);
    this.props.saveVehicleSetup(this.state.vehicleSetupFormValues);
  };

  handleFleetDataPath = () => {
    this.props.getValidateFleetDataPath(
      this.state.fleetFormValues.fleetDPath,
      this.state.fleetFormValues.fleetConType
    );
  };

  handleProdReset = () => {
    this.handleProdFleetClick(this.state.productSetupFormValues);
    // this.setState({
    //   vehicleSetupFormValues: {
    //     ...this.state.vehicleSetupFormValues,
    //     relThres: "",
    //     utiThres: "",
    //   },
    //   productSetupFormValues: {
    //     ...this.state.productSetupFormValues,
    //     relThresNotRelStat: "",
    //     utilThresAbNUtilStat: "",
    //     tempThresStrt: "",
    //     tempThresEnd: "",
    //     socThresStrt: "",
    //     socThresEnd: "",
    //     cylThres: "",
    //     crateThres: "",
    //     dSpan: "",
    //     dTempOne: "",
    //     dTempTwo: "",
    //     dTempThre: "",
    //     dTempFor: "",
    //     dTempFiv: "",
    //     dTempSix: "",
    //     dTempSev: "",
    //     dTempEit: "",
    //     dTempNin: "",
    //     dTempTen: "",
    //     dTempEle: "",
    //     dTempTwe: "",
    //     dSocOne: "",
    //     dSocTwo: "",
    //     dSocThre: "",
    //     dSocFor: "",
    //     dSocFiv: "",
    //     dSocSix: "",
    //     dSocSev: "",
    //     dSocEit: "",
    //     dSocNin: "",
    //     dSocTen: "",
    //     dPowOne: "",
    //     dPowTwo: "",
    //     dPowThre: "",
    //     dPowFor: "",
    //     dPowFiv: "",
    //     dPowSix: "",
    //     dPowSev: "",
    //     dPowEit: "",
    //     dPowNin: "",
    //     dPowTen: "",
    //     dPowEle: "",
    //     dPowTwe: "",
    //     dDodOne: "",
    //     dDodTwo: "",
    //     dDodThre: "",
    //     dDodFor: "",
    //     dDodFiv: "",
    //     dDodSix: "",
    //     dDodSev: "",
    //     dDodEit: "",
    //     dDodNin: "",
    //     dDodTen: "",
    //     essType: "",
    //     productType: "ESS",
    //     essArticleNumber: "",
    //     ratedCapacity: "",
    //     ratedVoltage: "",
    //     ratedEnergy: "",
    //     maxCurrent: "",
    //   },
    // });
  };

  onCloseNotfn = async () => {
    await this.props.clearSaveFleetResponce();
    this.setState({ showApiresnotification: false }, () => {
      this.props.getUsedFleetNameList();
      this.props.getSetupSidemenuList();
      this.handleSiteClick(null);
      // this.handleProdFleetClick(this.props.setupSidemenuList[0]);
    });
  };

  // getConnectivityKeyPathList = (value) => {
  //   if (!value) return; // if value is not selected do nothing

  //   let connectivityKeyPathList = [];

  //   if (this.props.fleetSetupData !== null) {
  //     if (value.length > 1) {
  //       // if value is conectivity type
  //       connectivityKeyPathList = this.props.fleetSetupData.ck.find(
  //         (item) => item.ct === value
  //       ).pt;
  //       connectivityKeyPathList = connectivityKeyPathList.find(
  //         (item) => item.pt == this.state.fleetFormValues.platform
  //       );
  //     } else {
  //       // if value is platform
  //       connectivityKeyPathList = this.props.fleetSetupData.ck.find(
  //         (item) => item.ct === this.state.fleetFormValues.fleetConType
  //       ).pt;
  //       connectivityKeyPathList = connectivityKeyPathList.find(
  //         (item) => item.pt == value
  //       );
  //     }
  //   }

  //   // update the connectivityKeyPath
  //   this.setState({
  //     fleetFormValues: {
  //       ...this.state.fleetFormValues,
  //       connectivityKeyPathList: connectivityKeyPathList,
  //       //connectivityKeyPath: "",
  //     },
  //   });
  // };

  handleProdFleetClick = async selectedFleet => {
    // Fleet data path validation
    this.props.fleetDataPathValidate === false &&
      this.props.getValidateFleetDataPathSuccess(null);

    await this.props.getFleetData();
    await this.props.clearSaveFleetResponce();

    if (
      selectedFleet === 'New fleet' ||
      selectedFleet.fleetName === 'New fleet'
    ) {
      sessionStorage.setItem(
        'fleetId',
        this.props.usedFleetNameList[0].fleetId
      );
      this.props.getPrdtSetupValues('');
      this.setState({
        siteFormVisible: false,
        fleetFormVisible: true,
        selectedFleet,
        fleetFormValues: {
          ...this.state.fleetFormValues,
          isDraft: false,
          webPortalLive: false,
          dataApiLive: false,
          isUpdate: false,
          fleetName: '',
          newFleetName: '',
          fleetId: this.props.usedFleetNameList[0].fleetId,
          fleetConType: '',
          username: '',
          password: '',
          connectivityKeyPath: '',
          connectivityKeyPathList: [],
          fleetDPath: '',
          fleetLinkDb: '',
          fleetTotalVehicle: '',
          fleetServices: ['ESS RCM'],
          Isplatform2: false,
          fleetVname: '',
          fleetVtype: '',
          fleetPtype: '',
          fleetPAvgthru: '',
          fleetFsav: '',
          displayFleetName: '',
          platform: '',
          designedThroughput: '',
          maxThroughput: '',
          firstLineStartColor: '',
          firstLineEndColor: '',
          secondLineStartColor: '',
          secondLineEndColor: '',
          slaStartDate: new Date(moment().subtract(1, 'days').startOf('day')),
          accessExpirationDetailList: [],
        },
        vehicleSetupFormValues: {
          ...this.state.vehicleSetupFormValues,
          fleetName: selectedFleet,
          vehiclePrefix: 'Vehicle',
          relThres: '',
          utiThres: '',
        },
        productSetupFormValues: {
          ...this.state.productSetupFormValues,
          fleetName: selectedFleet,
          batteryPrefix: '',
          productType: 'ESS',
          essArticleNumber: '',
          packPrefix: 'STRING',
          numberOfBatterySystem: 1,
          numberOfStrings: 1,
          ratedCapacity: '',
          ratedVoltage: '',
          ratedEnergy: '',
          maxCurrent: '',
          relThresNotRelStat: '',
          utilThresAbNUtilStat: '',
          tempThresStrt: '',
          tempThresEnd: '',
          socThresStrt: '',
          socThresEnd: '',
          cylThres: '',
          crateThres: '',
          dSpan: '',
          capacityAfterDesignedLifetime: '',
          throughputLifetime: '',
          designBudget: [],
          sohModelOutput: '',
          converterPrefix: 'TC',
          converterEnable: false,
        },
      });
    } else {
      this.props.getPrdtSetupValues(selectedFleet.fleetName);
      this.props.getPrdtInfoSetupValues(selectedFleet.fleetName);
      this.props.getVehicleSetup(selectedFleet.fleetName);
      this.props.getSetupFleetList(selectedFleet.fleetName);
      this.setState({
        siteFormVisible: false,
        fleetFormVisible: true,
        selectedFleet: selectedFleet.fleetName,
        fleetFormValues: {
          ...this.state.fleetFormValues,
          isDraft: false,
          webPortalLive: false,
          dataApiLive: false,
          isUpdate: false,
          fleetName: selectedFleet.fleetName,
          newFleetName: '',
          fleetId: this.props.usedFleetNameList[0].fleetId,
          fleetConType: '',
          username: '',
          password: '',
          connectivityKeyPath: '',
          fleetDPath: '',
          fleetLinkDb: '',
          fleetTotalVehicle: '',
          fleetServices: ['ESS RCM'],
          Isplatform2: false,
          fleetVname: '',
          fleetVtype: '',
          fleetPtype: '',
          fleetPAvgthru: '',
          fleetFsav: '',
          displayFleetName: '',
          platform: '',
          designedThroughput: '',
          maxThroughput: '',
          firstLineStartColor: '',
          firstLineEndColor: '',
          secondLineStartColor: '',
          secondLineEndColor: '',
          slaStartDate: new Date(moment().subtract(1, 'days').startOf('day')),
          accessExpirationDetailList: [],
        },
        vehicleSetupFormValues: {
          ...this.state.vehicleSetupFormValues,
          fleetName: selectedFleet.fleetName,
          vehiclePrefix: '',
          relThres: '',
          utiThres: '',
        },
        productSetupFormValues: {
          ...this.state.productSetupFormValues,
          fleetName: selectedFleet.fleetName,
          batteryPrefix: '',
          productType: 'ESS',
          essArticleNumber: '',
          packPrefix: 'STRING',
          numberOfBatterySystem: 1,
          numberOfStrings: 1,
          ratedCapacity: '',
          ratedVoltage: '',
          ratedEnergy: '',
          maxCurrent: '',
          relThresNotRelStat: '',
          utilThresAbNUtilStat: '',
          tempThresStrt: '',
          tempThresEnd: '',
          socThresStrt: '',
          socThresEnd: '',
          cylThres: '',
          crateThres: '',
          dSpan: '',
          capacityAfterDesignedLifetime: '',
          throughputLifetime: '',
          designBudget: [],
          sohModelOutput: '',
          converterPrefix: 'TC',
          converterEnable: false,
        },
      });
    }
  };

  commonDate_onApply = (event, picker) => {
    const start = picker.startDate;
    let result;
    const date1 = moment(start).format('MM DD,YYYY');
    const time1 = moment(start).format('HH:mm:ss');
    result = date1 + ' ' + time1;
    result = new Date(result);
    this.setState({
      fleetFormValues: {
        ...this.state.fleetFormValues,
        slaStartDate: result,
      },
    });
  };

  handleAddNotification = newNotification => {
    this.setState(prevState => ({
      fleetFormValues: {
        ...prevState.fleetFormValues,
        accessExpirationDetailList: [
          newNotification,
          ...prevState.fleetFormValues.accessExpirationDetailList,
        ],
      },
    }));
  };

  handleDeleteNotification = index => {
    this.setState(prevState => {
      const accessExpirationDetailList =
        prevState.fleetFormValues.accessExpirationDetailList.filter(
          (_, i) => i !== index
        );

      return {
        fleetFormValues: {
          ...prevState.fleetFormValues,
          accessExpirationDetailList,
        },
      };
    });
  };

  handleUpdateNotification = updatedList => {
    this.setState(prevState => ({
      fleetFormValues: {
        ...prevState.fleetFormValues,
        accessExpirationDetailList: updatedList,
      },
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Breadcrumb
          BreadcrumbIcon={<HomeIcon className={''} alt="Home" />}
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          MonitoredCount={this.props.monitoredCountData}
        />
        <div
          style={{
            position: 'absolute',
            top: 51,
            right: 10,
            zIndex: '999',
          }}>
          {this.props.savesiteresponce !== null &&
          this.props.savesiteresponce.length > 0 ? (
            <Toast
              onClose={this.onCloseNotfn}
              show={this.state.showApiresnotification}
              delay={1000}
              autohide
              className="apiresnotification">
              <Toast.Header>
                <CheckMarkCircle className="toasticon" />
                {this.props.savesiteresponce[0].msg}
              </Toast.Header>
            </Toast>
          ) : (
            ''
          )}
          {this.props.saveProductSetupResponce !== null &&
          this.props.saveProductSetupResponce.length > 0 &&
          this.props.saveProductInfoSetupResponce !== null &&
          this.props.saveProductInfoSetupResponce.length > 0 &&
          this.props.saveVehicleSetupResponce !== null &&
          this.props.saveVehicleSetupResponce.length > 0 &&
          this.props.savefleetresponce !== null &&
          this.props.savefleetresponce.length > 0 ? (
            <Toast
              onClose={this.onCloseNotfn}
              show={this.state.showApiresnotification}
              delay={1000}
              autohide
              className="apiresnotification">
              <Toast.Header>
                <CheckMarkCircle className="toasticon" />
                {this.props.savefleetresponce[0].msg}
              </Toast.Header>
            </Toast>
          ) : (
            ''
          )}
        </div>
        <Row className="addMarginTop">
          <Col className=" noLeftpadding noRightpadding">
            {/* <SiteSetupForm /> */}
            <FleetSetupTabs
              onUpdateNotifications={this.handleUpdateNotification}
              onDeleteNotification={this.handleDeleteNotification}
              onAddNotification={this.handleAddNotification}
              handleContractorFormChange={this.handleContractorFormChange}
              selectedTab={this.state.selectedTab}
              onFleetSetupTabClick={this.onFleetSetupTabClick}
              fleetList={this.props.fleetsListData}
              handleFleetSelect={this.handleFleetSelect}
              handleSearchClear={this.handleSearchClear}
              siteFormVisible={this.state.siteFormVisible}
              fleetFormVisible={this.state.fleetFormVisible}
              handleSiteClick={this.handleSiteClick}
              handleFleetClick={this.handleFleetClick}
              selectedSite={this.state.selectedSite}
              selectedFleet={this.state.selectedFleet}
              handleSiteInputChange={this.handleSiteInputChange}
              handleSiteSubmit={this.handleSiteSubmit}
              siteFormValues={this.state.siteFormValues}
              handleSiteReset={this.handleSiteReset}
              handleFleetInputChange={this.handleFleetInputChange}
              handleFleetSubmit={this.handleFleetSubmit}
              handleFleetReset={this.handleFleetReset}
              handleFleetDropdownChange={this.handleFleetDropdownChange}
              fleetFormValues={this.state.fleetFormValues}
              addSiteActive={this.state.addSiteActive}
              addFleetActive={this.state.addFleetActive}
              handleSiteAddressChange={this.handleSiteAddressChange}
              loadingsavesite={this.props.loadingsavesite}
              savesiteresponce={this.props.savesiteresponce}
              loadingsavefleet={this.props.loadingsavefleet}
              savefleetresponce={this.props.savefleetresponce}
              handleFleetCheckboxChange={this.handleFleetCheckboxChange}
              setupSiteList={this.props.setupSiteList}
              loadingSetupSiteList={this.props.loadingSetupSiteList}
              loadingSetupFleetList={this.props.loadingSetupFleetList}
              setupFleetList={this.props.setupFleetList}
              editSite={this.state.editSite}
              editFleet={this.state.editFleet}
              productSetupFormValues={this.state.productSetupFormValues}
              vehicleSetupFormValues={this.state.vehicleSetupFormValues}
              handleProdInputChange={this.handleProdInputChange}
              handleVehicleInputChange={this.handleVehicleInputChange}
              handleSohModelInputChange={this.handleSohModelInputChange}
              handleProdSubmit={this.handleProdSubmit}
              selectedProdSetupUsgsTab={this.state.selectedProdSetupUsgsTab}
              onProdSetupUsgsTabClick={this.onProdSetupUsgsTabClick}
              handleProdReset={this.handleProdReset}
              loadingproductSetup={this.props.loadingproductSetup}
              saveProductSetupResponce={this.props.saveProductSetupResponce}
              handleProdFleetClick={this.handleProdFleetClick}
              loadingPrdtSetupValues={this.props.loadingPrdtSetupValues}
              loadingproductInfoSetup={this.props.loadingproductInfoSetup}
              loadingPrdtInfoSetupValues={this.props.loadingPrdtInfoSetupValues}
              loadingSetupSidemenuList={this.props.loadingSetupSidemenuList}
              setupSidemenuList={this.props.setupSidemenuList}
              usedFleetNameList={this.props.usedFleetNameList}
              onPlaceSelected={this.onPlaceSelected}
              handleFleetDataPath={this.handleFleetDataPath}
              fleetDataPathValidate={this.props.fleetDataPathValidate}
              loadingFleetDataPathValidate={
                this.props.loadingFleetDataPathValidate
              }
              fleetSetupData={this.props.fleetSetupData}
              data={this.state.productSetupFormValues.designBudget}
              handleInputChange={this.handleChange}
              handleSwitchChange={this.handleSwitchChange}
              setColor={this.setColor}
              activeTabName={this.state.activeTabName}
              sohStressActiveTab={this.state.sohStressActiveTab}
              onAddRow={this.handleAddRow}
              onDeleteRow={this.handleRemoveRow}
              onInputChange={this.handleInputChange}
              commonDate_onApply={this.commonDate_onApply}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  monitoredCountData: monitoredCountDataSelector(),
  fleetsListData: fleetsListDataSelector(),
  loadingsavesite: loadingsavesiteSelector(),
  savesiteresponce: savesiteresponceSelector(),
  loadingsavefleet: loadingsavefleetSelector(),
  savefleetresponce: savefleetresponceSelector(),
  loadingSetupSiteList: loadingSetupSiteListSelector(),
  setupSiteList: setupSiteListSelector(),
  loadingSetupFleetList: loadingSetupFleetListSelector(),
  setupFleetList: setupFleetListSelector(),
  loadingproductSetup: loadingproductSetupSelector(),
  saveProductSetupResponce: saveProductSetupResponceSelector(),
  loadingproductInfoSetup: loadingproductInfoSetupSelector(),
  saveProductInfoSetupResponce: saveProductInfoSetupResponceSelector(),
  loadingPrdtSetupValues: loadingPrdtSetupValuesSelector(),
  prdtSetupValues: prdtSetupValuesSelector(),
  loadingPrdtInfoSetupValues: loadingPrdtInfoSetupValuesSelector(),
  prdtInfoSetupValues: prdtInfoSetupValuesSelector(),
  loadingVehicleSetup: loadingVehicleSetupSelector(),
  saveVehicleSetupResponce: saveVehicleSetupResponceSelector(),
  loadingVehicleSetupValues: loadingVehicleSetupValuesSelector(),
  vehicleSetupValues: vehicleSetupValuesSelector(),
  loadingSetupSidemenuList: loadingSetupSidemenuListSelector(),
  setupSidemenuList: setupSidemenuListSelector(),
  loadingUsedFleetNameList: loadingUsedFleetNameListSelector(),
  usedFleetNameList: usedFleetNameListSelector(),
  loadingFleetDataPathValidate: loadingFleetDataPathValidateSelector(),
  fleetDataPathValidate: fleetDataPathValidateSelector(),
  loadingFleetData: loadingFleetDataSelector(),
  fleetSetupData: fleetDataSelector(),
});

const mapDispatchToProps = {
  getMonitoredCount: fetchMonitoredCount,
  getFleetsList: fetchFleetsList,
  saveSite,
  saveFleet,
  clearSaveFleetResponce,
  clearSaveSiteResponce,
  getSetupSiteList,
  getSetupFleetList,
  saveproductSetup,
  saveproductInfoSetup,
  getPrdtSetupValues,
  getPrdtInfoSetupValues,
  getVehicleSetup,
  saveVehicleSetup,
  getSetupSidemenuList,
  getUsedFleetNameList,
  getValidateFleetDataPath,
  getValidateFleetDataPathSuccess,
  getFleetData,
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetSetup);
