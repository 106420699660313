import { months } from './constants';

export const getStartEndDate = pickerValue => {
  const regex = /^([A-Za-z]+)-(\d{4})\sto\s([A-Za-z]+)-(\d{4})$/;

  if (/^\d{4}$/.test(pickerValue)) {
    const year = parseInt(pickerValue, 10);

    const startDate = new Date(year, 0, 1);
    startDate.setDate(startDate.getDate() + 1);

    const endDate = new Date(year, 11, 31, 23, 59, 59, 999);

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
  } else if (regex.test(pickerValue)) {
    const [, startMonth, startYear, endMonth, endYear] =
      pickerValue.match(regex);

    const startDate = new Date(startYear, months.indexOf(startMonth), 1);
    startDate.setDate(startDate.getDate() + 1);

    const endDate = new Date(endYear, months.indexOf(endMonth) + 1, 0);
    endDate.setHours(23, 59, 59, 999);

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
  } else {
    return {
      startDate: new Date(new Date().getFullYear(), 0, 2).toISOString(),
      endDate: new Date(new Date().getFullYear(), 11, 31).toISOString(),
    };
  }
};
