import { fromJS } from 'immutable';
import {
  FETCH_EXCELREPORT_DATA,
  FETCH_EXCELREPORT_DATA_CLEAR,
  FETCH_EXCELREPORT_DATA_FAILURE,
  FETCH_EXCELREPORT_DATA_SUCCESS,
  FETCH_EXCELREPORT_EMAIL,
  FETCH_EXCELREPORT_EMAIL_SUCCESS,
  FETCH_FLEETWORDREPORT_DATA,
  FETCH_FLEETWORDREPORT_DATA_SUCCESS,
  FETCH_FLEETWORDREPORT_DATA_FAILURE,
} from './constants';

const initialState = fromJS({
  loadingExcelReport: false,
  excelReportData: null,
  error: null,
  loadingExcelReportEmail: false,
  excelReportEmail: null,
  loadingFleetWordReport: false,
  fleetWordReportData: null,
});

export function reducerExcelReport(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXCELREPORT_DATA:
      return state.set('loadingExcelReport', true).set('error', null);
    case FETCH_EXCELREPORT_DATA_SUCCESS:
      return state
        .set('loadingExcelReport', false)
        .set('excelReportData', action.excelReportData);
    case FETCH_EXCELREPORT_DATA_CLEAR:
      return state
        .set('loadingExcelReport', false)
        .set('excelReportData', null)
        .set('error', null);

    case FETCH_EXCELREPORT_EMAIL:
      return state.set('loadingExcelReportEmail', true).set('error', null);
    case FETCH_EXCELREPORT_EMAIL_SUCCESS:
      return state
        .set('loadingExcelReportEmail', false)
        .set('excelReportEmail', action.excelReportEmail);

    case FETCH_EXCELREPORT_DATA_FAILURE:
      return (
        state
          .set('loadingExcelReport', false)
          // .set("excelReportData", null)
          .set('error', action.error)
      );

    case FETCH_FLEETWORDREPORT_DATA:
      return state.set('loadingFleetWordReport', true).set('error', null);
    case FETCH_FLEETWORDREPORT_DATA_SUCCESS:
      return state
        .set('loadingFleetWordReport', false)
        .set('fleetWordReportData', action.fleetWordReport);

    case FETCH_FLEETWORDREPORT_DATA_FAILURE:
      return (
        state
          .set('loadingFleetWordReport', false)
          // .set("fleetWordReportData", null)
          .set('error', action.error)
      );

    default:
      return state;
  }
}
