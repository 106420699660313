import toast from 'react-hot-toast';
import { introjs } from '../IntroJs/Steps';

export const fleetStatisticsSteps = selectedFleet => {
  return [
    {
      step: 0,
      element: '[data-intro="site-name"]',
      intro: 'Welcome to Condition Monitoring for Traction.',
    },

    {
      step: 1,
      element: `[data-intro="${selectedFleet.nm}"]`,
      intro: '',
    },
    {
      step: 2,
      element: `[data-intro="${selectedFleet.nm}"]`,
      intro:
        'Click on a fleet to explore how your traction batteries are operating.',
    },
    {
      step: 3,
      element: '[data-intro=""]',
      intro:
        'The main page provides a view on your batteries recent operation.',
    },
    {
      step: 4,
      element: '[data-intro=""]',
      intro: '',
    },
    {
      step: 5,
      element: '[data-intro="fleet-satistics"]',
      intro:
        "Click on the Statistics tab to see a summary of your fleet's battery data.",
    },
    {
      step: 6,
      element: '[data-intro="fleet-satistics-content"]',
      tooltipPosition: 'top',
      intro:
        'The Statistics tab provides histograms for specific variables across your entire fleet, aggregating data from individual batteries over a selected time period. It helps you visualize the distribution of a given variable, enabling you to identify patterns, such as systematic issues and spot any extreme values that could indicate potential concerns.',
    },
    {
      step: 7,
      element: '[data-intro=""]',
      intro: '',
    },
    {
      step: 8,
      element: '[data-intro="fleet-satistics-variable"]',
      intro: 'Select the variable that you want to visualize.',
    },
    {
      step: 9,
      element: '[data-intro="fleet-satistics-vehicle"]',
      intro:
        "Select 'All vehicles' for a fleet-wide overview, or pick a specific vehicle from the drop down list.",
    },
    {
      step: 10,
      element: '[data-intro="fleet-satistics-timeHorizon"]',
      intro: 'Select the desired time range for the data you wish to view.',
    },
    {
      step: 11,
      element: '[data-intro="fleet-satistics-apply"]',
      intro: "Click 'Apply'.",
    },
    {
      step: 12,
      element: '[data-intro="fleet-satistics-columns-6"]',
      intro:
        'Click on any bar to filter the list below with batteries operated within the selected range and period.',
    },
    {
      step: 13,
      element: '[data-intro=""]',
      intro: '',
    },
    {
      step: 14,
      element: '[ data-intro="fleet-satistics-list"]',
      intro:
        'The batteries are sorted based on the highest value of the Y-axis metric for the selected variable. For instance, if you choose Temperature, the Y-axis represents operating hours, and the battery with the most hours in the specific temperature range will appear first. For other variables, like Power, the batteries will be sorted by their throughput (kWh).',
    },
    {
      step: 15,
      element: '[ data-intro="fleet-satistics-arrow"]',
      intro:
        "Click on the arrow to be navigated to the battery's histogram for more detailed insights.",
    },
    {
      step: 16,
      element: '[data-intro=" "]',
      intro:
        'The Fleet Statistics guide ends here.  For more information, you can see the online help or other user guides.',
    },
  ];
};

export const fleetStatisticsStepChange = (step, selectedFleet) => {
  try {
    if (step === 0) {
      document.querySelector('[data-intro="site-name"]').click();
    }
    if (step === 1) {
      introjs.goToStep(2);
      introjs.start();
    }
    if (step === 3) {
      document.querySelector(`[data-intro="${selectedFleet.nm}"]`).click();
    }
    if (step === 4) {
      introjs.goToStep(5);
      introjs.start();
    }
    if (step === 6) {
      document.querySelector('[data-intro="fleet-satistics"]').click();
    }
    if (step === 7) {
      introjs.goToStep(8);
      introjs.start();
    }
    if (step === 12) {
      console.log('step 12');
      document
        .querySelector('[data-intro="fleet-satistics-columns-6"]')
        .point.firePointEvent('click');
    }
    if (step === 13) {
      introjs.goToStep(14);
      introjs.start();
    }
    if (step === 16) {
      document.querySelector('[data-intro="site-name"]').click();
    }
  } catch (err) {
    toast.error('Error in user guide! Please start again.');
    introjs.exit(true);
  }
};
