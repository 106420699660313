export const FETCH_EXCELREPORT_DATA = 'FETCH_EXCELREPORT_DATA';
export const FETCH_EXCELREPORT_DATA_SUCCESS = 'FETCH_EXCELREPORT_DATA_SUCCESS';
export const FETCH_EXCELREPORT_DATA_FAILURE = 'FETCH_EXCELREPORT_DATA_FAILURE';

export const FETCH_EXCELREPORT_EMAIL = 'FETCH_EXCELREPORT_EMAIL';
export const FETCH_EXCELREPORT_EMAIL_SUCCESS =
  'FETCH_EXCELREPORT_EMAIL_SUCCESS';
export const FETCH_EXCELREPORT_DATA_CLEAR = 'FETCH_EXCELREPORT_DATA_CLEAR';

export const FETCH_FLEETWORDREPORT_DATA = 'FETCH_FLEETWORDREPORT_DATA';
export const FETCH_FLEETWORDREPORT_DATA_SUCCESS =
  'FETCH_FLEETWORDREPORT_DATA_SUCCESS';
export const FETCH_FLEETWORDREPORT_DATA_FAILURE =
  'FETCH_FLEETWORDREPORT_DATA_FAILURE';
