import React from 'react';
import { Col } from 'react-bootstrap';

const FleetWordReport = ({ fleetWordReportData }) => {
  return (
    <>
      <Col className="containerreportpreviewtitlebar">
        <div className="reportview-menubar">
          <div className="reportview-title">Report preview</div>
        </div>
      </Col>
      <div className="reportpreview">
        <div className="generatedreportcontainer">
          <h6>
            <b className="reddash">____</b>
            <br />
            <b>ABB TRACTION SERVICE</b>
          </h6>
          <h4>
            <b>{fleetWordReportData.fleetName} Traction Batteries</b>
          </h4>
          <h4>
            Condition Monitoring Fleet Report
            <br />
            {fleetWordReportData.reportingPeriod}
          </h4>
          <div className="reportmainimg">
            <img
              src={require('../../assets/images/report/report_main.jpg')}
              className="mainimg"
            />
          </div>
          <h4>
            <b>Contents</b>
          </h4>
          <h6>
            <ol type="1">
              <b>
                <li>Purpose and Basic Description</li>
                <li>Scope</li>
                <li>Service Packages Self-Assessment-2023</li>
                <li>Utilization Traction battery</li>
              </b>
              <ul type="square">
                <li>Fleet Level</li>
                <ul type="disc">
                  <li>Overview KPIs </li>
                  <li>State of Charge (SoC) </li>
                  <li>Temperature Exposure</li>
                </ul>
                <li>Vehicle Level</li>
                <ul type="disc">
                  <li>Data and Connectivity Health</li>
                  <li>Status Diagnostic Service Events</li>
                </ul>
                <li>Traction battery Level</li>
                <ul type="disc">
                  <li>Overview</li>
                  <li>Further Observations and Recommendations</li>
                  <li>Observation to Application Use and Further Proceeding</li>
                  <li>Resolution Lead-time and Technical Support Trigger</li>
                  <li>Configuration Management / Changes</li>
                </ul>
                <li>Additional Information</li>
                <ul type="disc">
                  <li>Configuration Changes</li>
                  <li>Listing of related documents</li>
                </ul>
              </ul>
            </ol>
          </h6>
          <h4>
            <b>1. Purpose and Basic Description</b>
          </h4>
          <div className="description">
            This report compiles the performance data and analysis of the{' '}
            {fleetWordReportData.fleetName} fleet. This report comprises
            following configuration:
            <ul type="disc">
              <li>
                {fleetWordReportData.data.totalVehicle} Vehicles(
                {fleetWordReportData.data.activeVehicle} active vehicles)
              </li>
              <li>
                {fleetWordReportData.data.totalBattery} +{' '}
                {fleetWordReportData.data.spareBattery} spare Traction batteries
                ({fleetWordReportData.data.activeBattery} active Traction
                batteries)
              </li>
              Type: {fleetWordReportData.data.type}
            </ul>
            Each vehicle fitting {fleetWordReportData.data.batteryPerVehicle}{' '}
            Traction batteries and {fleetWordReportData.data.btms} Battery
            Thermal Management Systems (BTMS). The fleet entered commercial
            operation with batteries by {fleetWordReportData.data.slaDate}.
            <br />
            <br />
            The fleet’s performance assessment is deducted and evaluated from
            following data sources:
            <br />
            <ul type="disc">
              <li>Consolidated Data from Condition Monitoring (CM)</li>
              <li>
                Case information recorded over the Ticketing System (Bordline
                Service Portal)
              </li>
              <li>
                Diagnostic Data retrieved out of the BMS Controller Units (if
                applicable)
              </li>
              <li>
                Diagnostic Data retrieved out of the cooling systems (if
                applicable)
              </li>
              <li>
                Recorded Data out of ABB’s ERP-System (SAP, Repair Record)
              </li>
              <li>
                Diagnostic Data of the batteries’ «offline health diagnosis»
              </li>
            </ul>
          </div>
          <h4>
            <b>2. Scope</b>
          </h4>
          <div className="description">
            The content shown in this document is based on gathered information
            from the above-mentioned sources.
            <ul type="disc">
              <li>
                This report considers data in the period from 01.01.2023 to
                31.12.2023. References to previous years are made if relevant.
              </li>
              <li>
                Data connectivity and FTP Storage are being handled by the train
                manufacturer. For availability of data packages from the
                vehicles, refer to chapter 3.2.1.
              </li>
              The parties’ feedback is welcome, contributing to the content
              model and KPI incorporation of this report.
            </ul>
          </div>
          <h4>
            <b>3. Service Packages Self-Assessment-2023</b>
          </h4>
          <div className="description">
            The self-assessment on the delivery of service packages is reflected
            in Table 1 providing a status based on a traffic light system.
            <br />
            <br />
            Table 1: ABB Service Package Self-Assessment 2023
          </div>
          <h4>
            <b>4. Utilization Traction battery</b>
          </h4>
          <div className="description">
            This chapter displays the utilization of the traction batteries on a
            fleet level within the re-port period providing information on the
            usage and operational pattern of the systems through several KPIs
            subjected to history track and trend development.
          </div>
          <h5>
            <b>4.1. Fleet Level</b>
          </h5>
          <h6>
            <b>4.1.1. Overview KPIs</b>
          </h6>
          <div className="description">
            Table 2 reflected in this chapter contains the key performance
            indicators applied to the fleet cumulated over the report period.
            <br />
            <br />
            Table 2: Global Fleet KPIs – ARRIVA GTW
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '50%' }}>Measure</th>
                  <th style={{ width: '10%' }}>2021</th>
                  <th style={{ width: '10%' }}>2022</th>
                  <th style={{ width: '10%', textAlign: 'center' }}>Unit</th>
                </tr>
                <tr>
                  <td>
                    Number of operational traction batteries in fleet during
                    reporting period
                  </td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'center' }}></td>
                </tr>
                <tr>
                  <td>
                    Total avg. energy throughput kWh/day per bat-tery (includes
                    charging and discharging energy)
                  </td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'center' }}>-</td>
                </tr>
                <tr>
                  <td>Daily nominal energy throughput value per bat-tery</td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'center' }}>kWh / Day</td>
                </tr>
                <tr>
                  <td>State of Health SoH (Fleet’s avg.)</td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'center' }}>%</td>
                </tr>
                <tr>
                  <td>State of Health SoH – Minimal value </td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'center' }}>%</td>
                </tr>
                <tr>
                  <td>State of Health SoH – Maximal value </td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'center' }}>%</td>
                </tr>
              </table>
            </div>
            <ul type="disc">
              <li>
                State of Health (SoH): The indicative values of State of Health
                are estimated values based on the energy throughput received
                through the Condition Monitoring data. This SoH model does not
                reflect data of increased stress caused by temperature, SoC,
                Power and Depth of Discharge. Accurate values are to be
                evaluated on individual batteries through measurement.
              </li>
              <li>
                The average State of Health of the batteries in 2023 is 97.3%. A
                difference of 1.6% with the year 2022.
              </li>
            </ul>
          </div>
          <h6>
            <b>4.1.2. State of Charge (SoC)</b>
          </h6>
          <div className="description">
            State of Charge (SoC) represents the current energy level of a
            battery, expressed as a percentage of its total capacity. It
            provides information about how much energy is available for use.
            Ideal values for SoC of traction batteries are between 5% and 95 %.{' '}
          </div>
          <h6>
            <b>4.1.2.1. High value SoC</b>
          </h6>
          <div className="description">
            Table 3: Batteries that displayed more than 90%SoC.
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '15%' }}>Battery SN</th>
                  <th style={{ width: '15%' }}>PEC SN</th>
                  <th style={{ width: '15%' }}>
                    Vehicle (at time of this report)
                  </th>
                  <th style={{ width: '15%' }}>SoC{'>'}90% [hours]</th>
                  <th style={{ width: '15%' }}>In operation since …</th>
                  <th style={{ width: '15%' }}>SoH% at time of report</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <h6>
            <b>4.1.2.2. Low value SoC</b>
          </h6>
          <div className="description">
            Table 4: Battery that displayed less than 10%SoC.
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '15%' }}>Battery SN</th>
                  <th style={{ width: '15%' }}>PEC SN</th>
                  <th style={{ width: '15%' }}>
                    Vehicle (at time of this report)
                  </th>
                  <th style={{ width: '15%' }}>SoC{'<'}10% [hours]</th>
                  <th style={{ width: '15%' }}>In operation since …</th>
                  <th style={{ width: '15%' }}>SoH% at time of report</th>
                  <th style={{ width: '15%' }}>Resolu-tion </th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <h6>
            <b>4.1.3. Temperature Exposure</b>
          </h6>
          <div className="description">
            The Temperature is measured in degree Celsius (°C) on different
            places on a battery module. The ideal and recommended range for
            temperature is between 0°C and 38°C.
          </div>
          <h6>
            <b>4.1.3.1. High Temperatures</b>
          </h6>
          <div className="description">
            Table 5: Batteries with more than 1hour {'>'}40°C.{' '}
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '15%' }}>Battery SN</th>
                  <th style={{ width: '15%' }}>PEC SN</th>
                  <th style={{ width: '15%' }}>
                    Vehicle (at time of this report)
                  </th>
                  <th style={{ width: '15%' }}>{'>'}40C [hours]</th>
                  <th style={{ width: '15%' }}>In operation since …</th>
                  <th style={{ width: '15%' }}>SoH% at time of report</th>
                  <th style={{ width: '15%' }}>Comments</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <h6>
            <b>4.1.3.2. Low Temperatures</b>
          </h6>
          <div className="description">
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '15%' }}>Battery SN</th>
                  <th style={{ width: '15%' }}>PEC SN</th>
                  <th style={{ width: '15%' }}>
                    Vehicle (at time of this report)
                  </th>
                  <th style={{ width: '15%' }}>{'<'}0C [hours]</th>
                  <th style={{ width: '15%' }}>In operation since …</th>
                  <th style={{ width: '15%' }}>SoH% at time of report</th>
                  <th style={{ width: '15%' }}>Comments</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <h5>
            <b>4.2. Vehicle level</b>
          </h5>
          <div className="description">
            This chapter aims at providing further information on reporting on a
            vehicle level.
          </div>
          <h6>
            <b>4.2.1. Data and Connectivity Health</b>
          </h6>
          <div className="description">
            The relevant configuration management details are set up and
            maintained by the customer and shall be shared with ABB to make up
            for any configuration changes and corrections.
          </div>
          <h6>
            <b>4.2.2. Status Diagnostic Service Events</b>
          </h6>
          <div className="description">
            This chapter contains a summary of service events /raised tickets
            over ABB’s Ticketing Tool System throughout the report period. The
            information is captured in Table 6.
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '15%' }}>Vehicle-Nr.</th>
                  <th style={{ width: '15%' }}>Battery</th>
                  <th style={{ width: '15%' }}>Ticket</th>
                  <th style={{ width: '15%' }}>Ticket Raised on</th>
                  <th style={{ width: '15%' }}>Solved by:</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <h5>
            <b>4.3. Traction battery level</b>
          </h5>
          <div className="description">
            This chapter is based on the global fleet analysis and its KPIs and
            breaks them down to an individual battery level providing insights
            into each individual asset, allowing a long-term monitoring and
            trend tracking to support the operational lifetime goals of the
            fleet.
          </div>
          <h6>
            <b>4.3.1. Overview</b>
          </h6>
          <div className="description">
            To provide insights on all batteries in a clear manner a data
            extract has been provided. Please refer to the Excel file INSERT
            EXCEL FILE NAME and the sheet INSERT TAB NAME, where the SoH and
            Energy Throughput of each battery are available.{' '}
          </div>
          <h6>
            <b>4.3.2. Further Observations and Recommendations</b>
          </h6>
          <div className="description">
            This chapter contains information about further identified
            improvement room and instances of operational deviation pattern
            which are to be avoided. Each subtopic includes recom-mendations as
            the base for an exchange basis to implement countermeasures if
            applicable.
          </div>
          <h6>
            <b>4.3.3. Observation to Application Use and Further Proceeding</b>
          </h6>
          <div className="description">
            The parties have agreed on gathering representative operational data
            for a considerable period of time to jointly analyze the overall
            operational picture and jointly model counter-measures if
            applicable.{' '}
          </div>
          <h6>
            <b>4.3.4. Resolution Lead-time and Technical Support Trigger</b>
          </h6>
          <div className="description">
            Throughout the year a considerable number of tickets required
            several weeks upon diag-nose and recommendation for actual measures
            to be implemented. However, prolonging a disturbed battery state not
            only cuts the battery’s availability short, but can lead to
            conse-quential damage if not treated in time, thus ABB recommends:
            <ul type="disc">
              <li>Consulting ABB prior to performing corrective actions</li>
              <li>Retaking support trigger role via Ticketing Tool</li>
              <li>
                Prioritizing the battery’s corrective maintenance based on what
                is possible
              </li>
            </ul>
          </div>
          <h6>
            <b>4.3.5. Configuration Management / Changes</b>
          </h6>
          <div className="description">
            When exchanging a battery, ABB often expected for certain battery SN
            to be returned based on the IP Address and Vehicle placement
            assumption. To keep high connectivity and data fidelity levels ABB
            recommends:
            <ul type="disc">
              <li>
                Report as soon as executed HW exchanges to ABB especially PEC
                controllers and battery,
              </li>
              <li>
                When replacing a battery, please utilize existing IP-Addresses
                instead of creating new,
              </li>
              <li>
                If creation of new IP-Addresses is required, please delete old
                ones within FTP Server and transfer ftp data from old to new
                prior to doing so communicating it to ABB.
              </li>
            </ul>
            All configuration data (battery serial number, PEC serial number,
            IP-addresses) are available on the Remote Condition Monitoring.
          </div>
          <h5>
            <b>4.4. Additional Information</b>
          </h5>
          <h6>
            <b>4.4.1. Configuration Changes</b>
          </h6>
          <div className="description">
            This chapter contains a log of battery exchanges in 2023 key to
            record and maintain for the configuration management recorded in
            Table 7, meaning parsing of equipment from PEC controller, battery
            and vehicle level down to the assigned IP- Address as well as the
            correct, complete collection of data for each individual asset.
            <br />
            <br />
            Table 7: Configuration Changes ARRIVA GTW
            <br />
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '15%' }}>Approx. date of exchange</th>
                  <th style={{ width: '15%' }}>Vehicle</th>
                  <th style={{ width: '15%' }}>Old Battery SN</th>
                  <th style={{ width: '15%' }}>New Battery SN</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <h6>
            <b>4.4.2. Listing of related documents</b>
          </h6>
          <div className="description">
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '15%' }}>Ref #</th>
                  <th style={{ width: '15%' }}>Document Kind, Title</th>
                  <th style={{ width: '15%' }}>Document No.</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FleetWordReport;
