import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { createStructuredSelector } from 'reselect';
import { CaretDown } from '../../assets/images';
import Breadcrumb from '../../components/Breadcrumb';
import ExcelReportPreview from '../../components/ExcelReportPreview';
import FleetWordReport from '../../components/FleetWordReport/FleetWordReport';
import Loading from '../../components/Loading';
import Overview from '../../components/Overview';
import PieGraph from '../../components/PieGraph';
import SingleGraph from '../../components/SingleGraph';
import StockGraph from '../../components/StockGraph';
import Unauthorized from '../../components/Unauthorized/Unauthorized';
import UtilizationThresholdInfo from '../../components/UtilizationThresholdInfo';
import {
  checkUserEntitlements,
  userConfig as configUser,
} from '../../userConfig';
import {
  generateFleetWordReport,
  generateReport,
  generateReportData,
} from '../../utils/report-template-utils';
import { monitoredCountDataSelector } from '../DashBoard/selectors';
import { fetchEventList } from '../Events/actions';
import {
  eventListSelector,
  loadingEventListSelector,
} from '../Events/selectors';
import { fetchFleetsList, fetchVehiclesList } from '../FleetView/actions';
import {
  fleetsListDataSelector,
  loadingVehiclesSelector,
  overviewDataSelector,
  vehiclesListDataSelector,
} from '../FleetView/selectors';
import {
  clearChartData,
  fetchBatteryRelData,
  fetchBatteryStrData,
  fetchOperatingHoursHistogramData,
  fetchTemperatureHistogramData,
  fetchUsgDodHistogramData,
  fetchUsgPowHistogramData,
  fetchUsgSocHistogramData,
} from '../VehicleProductsView/actions';
import {
  batteryRelDataSelector,
  batteryStrDataSelector,
  operatingHoursHistogramDataSelector,
  temperatureHistogramDataSelector,
  usgDodHistogramDataSelector,
  usgPowHistogramDataSelector,
  usgSocHistogramDataSelector,
} from '../VehicleProductsView/selectors';
import {
  clearExcelReportData,
  fetchExcelReportData,
  fetchExcelReportEmailData,
  fetchFleetWordReportData,
} from './actions';
import messages from './messages';
import {
  excelReportDataSelector,
  excelReportEmailSelector,
  fleetWordReportSelector,
  loadingExcelReportEmailSelector,
  loadingExcelReportSelector,
  loadingFleetWordReportSelector,
} from './selectors';
import './styles.scss';

let userConfig;
let list_temparture = 0;
let list_crate = 0;
let list_soc = 0;
let list_cycle = 0;
let list_filecount = 0;
let vehicleCount = 0;
let isValid = false;
let stParam, ftParam, vcParam, vpParam, pkParam;

export class ReportView extends React.Component {
  constructor(props) {
    super(props);

    const url = new URL(window.location.href);
    stParam = url.searchParams.get('st');
    ftParam = url.searchParams.get('ft');
    vcParam = url.searchParams.get('vc');
    vpParam = url.searchParams.get('pdn');
    pkParam = url.searchParams.get('pkdn');

    userConfig = configUser();
    this.state = {
      filterItems: {
        availability: '',
        reliability: '',
        utilization: '',
        isAutoClick: null,
      },
      fleetList: [],
      vehicleList: [],
      essList: [],
      selectedSite: null,
      selectedPlatform: null,
      selectedPlatformGenerate: null,
      selectedFleet: null,
      selectedVehicle: null,
      selectedESS: null,
      selectedESSan: null,
      selectedESSip: null,
      packList: [],
      selectedPack: null,
      selectedPackan: null,
      selectedPackip: null,
      reportName:
        'ESS Report-' +
        Moment(new Date(Moment().subtract(0, 'days').endOf('day'))).format(
          'MM.DD.YYYY'
        ),
      enableValue: false,
      enableDownloadBtn: false,
      emailSelection: false,
      fleetReportOptions: {
        radio_Btn: 'r_word',
        m_stress: false,
        l_reliable: false,
        m_enrgy: false,
        l_enrgy: false,
        type: 'generate',
        uploadFile: null,
        reportName:
          'Fleet Report-' +
          Moment(new Date(Moment().subtract(0, 'days').endOf('day'))).format(
            'MM.DD.YYYY'
          ),
      },
      selectedTab: 'essreport',

      startDate: new Date(Moment().subtract(1, 'days').startOf('day')),
      startTime: new Date(Moment().subtract(1, 'days').startOf('day')),
      endDate: new Date(Moment().subtract(1, 'days').endOf('day')),
      endTime: new Date(Moment().subtract(1, 'days').endOf('day')),
      dateIndex: 1,
      essCount: 1,
      packCount: 1,
      isAccessReport: false,
      selectedSla: null,
      slaList: [],
      // isSameDate: true,
    };
    this.datepickerRef = React.createRef();
  }

  componentDidMount() {
    this.props.clearExcelReportData();
    this.setState({
      isAccessReport: checkUserEntitlements('ESS:Fleet-Report'),
    });

    this.props.clearChartData();

    this.props.fleetsListData === null &&
      this.props.getFleetsList('All', 'All', 'All', true, true, true);

    if (this.props.fleetsListData !== null) {
      const fleet = [];
      this.props.fleetsListData.forEach(item => {
        fleet.push(item.nm);
      });
      this.setState({ fleetList: fleet });
    }

    // if (this.props.vehiclesListData !== null) {
    //   let val = [];
    //   vehicleCount = 0;
    //   essCount = 0;
    //   this.props.vehiclesListData[0].vst.map((item) => {
    //     val.push(item.vn);
    //     vehicleCount++;
    //     item.vdat.map((ess) => {
    //       essCount++;
    //     });
    //   });
    //   this.setState({ vehicleList: val });
    // }
    // this.generateReport();

    ftParam !== null && this.setSelectedValues();
  }

  setSelectedValues = async () => {
    if (
      this.props.fleetsListData !== null &&
      this.props.vehiclesListData !== null
    ) {
      if (ftParam !== null) {
        const platform = this.props.fleetsListData.filter(
          item => item.nm === ftParam
        )[0].pl;
        const vehicles = [];

        this.props.vehiclesListData !== null &&
          this.props.vehiclesListData[0].vst.forEach(item => {
            vehicles.push(item.vn);
          });

        const sla = this.props.fleetsListData.filter(
          item => item.nm === ftParam
        )[0].fs;

        this.setState({
          selectedSite: stParam,
          selectedFleet: ftParam,
          vehicleList: vehicles,
          selectedPlatform: platform,
          slaList: sla,
        });
      }

      if (vcParam !== null) {
        const essdn = [];
        this.props.vehiclesListData[0].vst.forEach(item => {
          if (vcParam === item.vn) {
            item.vdat.forEach(ess => {
              if (ess.type !== 'converter') essdn.push(ess.dn);
            });
          }
        });
        this.setState(
          {
            selectedVehicle: vcParam,
            essList: essdn,
          },
          () => this.enableCheck()
        );
      }

      if (vpParam !== null) {
        const packdn = [];
        const platform = this.props.fleetsListData.filter(
          item => item.nm === ftParam
        )[0].pl;
        this.props.vehiclesListData[0].vst.forEach(item => {
          item.vdat.forEach(item_ess => {
            if (vpParam === item_ess.dn) {
              platform === '3' &&
                item_ess.pdat.forEach(pack => {
                  packdn.push(pack.dn);
                });

              this.setState(
                {
                  selectedESS: vpParam,
                  selectedESSan: item_ess.an,
                  selectedESSip: item_ess.ip,
                  packList: packdn,
                },
                () => this.enableCheck()
              );
              sessionStorage.setItem('selectedProductIP', item_ess.ip);
            }
          });
        });
      }

      if (pkParam !== null) {
        this.props.vehiclesListData[0].vst.forEach(item => {
          item.vdat.forEach(item_ess => {
            if (item_ess.dn === vpParam) {
              item_ess.pdat.forEach(item_pack => {
                if (pkParam === item_pack.dn) {
                  this.setState(
                    {
                      selectedPack: pkParam,
                      selectedPackan: item_pack.an,
                      selectedPackip: item_pack.ip,
                    },
                    () => this.enableCheck()
                  );
                  sessionStorage.setItem('selectedPack', item_pack.an);
                }
              });
            }
          });
        });
      }
    }
  };

  UNSAFE_componentWillReceiveProps(newProps, prevState) {
    if (
      this.props.fleetsListData !== newProps.fleetsListData &&
      newProps.fleetsListData !== null
    ) {
      const fleet = [];
      newProps.fleetsListData.forEach(item => {
        fleet.push(item.nm);
      });
      this.setState({ fleetList: fleet });
    }

    if (
      this.props.vehiclesListData !== newProps.vehiclesListData &&
      newProps.vehiclesListData !== null
    ) {
      const val = [];
      vehicleCount = 0;
      let essCountVal = 0;
      let packCountVal = 0;

      newProps.vehiclesListData[0].vst.forEach(item => {
        val.push(item.vn);
        vehicleCount++;
        item.vdat.forEach(ess => {
          essCountVal = essCountVal + 1;
          ess.pdat.forEach(pack => {
            packCountVal = packCountVal + 1;
          });
        });
      });

      this.setState({
        vehicleList: val,
        essCount: essCountVal,
        packCount: packCountVal,
      });
    }

    if (newProps.loadingExcelReportEmail === true) {
      // toast("Sending email...");
    } else {
      if (
        this.props.excelReportEmail !== newProps.excelReportEmail &&
        newProps.excelReportEmail !== null &&
        newProps.excelReportEmail.msg !== null &&
        newProps.excelReportEmail.msg === 'Email send successfully'
      ) {
        toast.dismiss();
        toast.success('Email sent successfully');
      }
    }
  }

  checkDateValidation = () => {
    // check the dates
    const diff = Moment(
      new Date(
        this.state.endDate.getFullYear(),
        this.state.endDate.getMonth(),
        this.state.endDate.getDate(),
        this.state.endTime.getHours(),
        this.state.endTime.getMinutes()
      )
    ).diff(
      Moment(
        new Date(
          this.state.startDate.getFullYear(),
          this.state.startDate.getMonth(),
          this.state.startDate.getDate(),
          this.state.startTime.getHours(),
          this.state.startTime.getMinutes()
        )
      )
    );
    const d = Moment.duration(diff);

    const days = Math.floor(d.asHours() / 24);
    const validateDays = d.asHours() / 24;
    const hours = Math.floor(d.asHours() - days * 24);
    const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));
    // let msg = days > 0 ? ` ${days} days` : '';
    // msg += hours > 0 || minutes > 0 ? ` ${hours} hours` : '';
    // msg += minutes > 0 ? ` ${minutes} minutes` : '';

    if (validateDays === 0 && hours === 0 && minutes === 0) {
      return false;
    } else if (validateDays < 0 || hours < 0 || minutes < 0) {
      return false;
    } else if (validateDays > 365) {
      return false;
    } else {
      return true;
    }
  };

  generateWordReport = () => {
    this.props.getFleetsList('All', 'All', 'All', true, true, true);
    // this.props.vehiclesListData === null &&
    this.props.getVehiclesList(
      this.state.selectedSite,
      this.state.selectedFleet
    );
    // this.props.operatingHoursHistogramData === null &&
    this.props.getOperatingHoursHistogramData(
      this.state.selectedVehicle,
      this.state.selectedESSan,
      Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      // "2023-02-05T00:00:00",
      // "2023-03-06T23:59:59",
      'Custom'
    );
    // this.props.temperatureHistogramData === null &&
    this.props.getTemperatureHistogramData(
      this.state.selectedVehicle,
      this.state.selectedESSan,
      Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      // "2023-02-05T00:00:00",
      // "2023-03-06T23:59:59",
      'Custom'
    );
    // this.props.usgSocHistogramData === null &&
    this.props.fetchUsgSocHistogramData(
      this.state.selectedVehicle,
      this.state.selectedESSan,
      Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      // "2023-02-05T00:00:00",
      // "2023-03-06T23:59:59",
      'Custom'
    );
    // this.props.usgPowHistogramData === null &&
    this.props.fetchUsgPowHistogramData(
      this.state.selectedVehicle,
      this.state.selectedESSan,
      Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'), // "2023-02-05T00:00:00",
      Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'), // "2023-03-06T23:59:59",
      // "2023-02-05T00:00:00",
      // "2023-03-06T23:59:59",
      'Custom'
    );
    this.props.fetchUsgDodHistogramData(
      this.state.selectedVehicle,
      this.state.selectedESSan,
      Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      // "2023-02-05T00:00:00",
      // "2023-03-06T23:59:59",
      'Custom'
    );
    // this.props.batteryRelData === null &&
    this.props.getBatteryRelData(
      this.state.selectedVehicle,
      this.state.selectedESSan
    );
    // this.props.batteryStrData === null &&
    this.props.getBatteryStrData(
      this.state.selectedVehicle,
      this.state.selectedESSan
    );
    // this.props.eventList === null &&
    this.props.fetchEventList(
      this.state.selectedFleet,
      this.state.selectedVehicle,
      this.state.selectedESSan,
      Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      'Any time', // "Custom range",
      '/Info/Warning/Error',
      this.state.selectedVehicle, // "All vehicle",
      this.state.selectedESS, // "All product",
      '',
      ''
    );
  };

  generateExcelReport = () => {
    this.setState(
      {
        fleetReportOptions: {
          ...this.state.fleetReportOptions,
          type: 'generate',
        },
        selectedPlatformGenerate: this.state.selectedPlatform,
      },
      () =>
        this.props.fetchExcelReportData(
          this.state.selectedFleet,
          Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
          Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
          this.state.fleetReportOptions
        )
    );
  };

  emailExcelReport = () => {
    toast('Sending email...');
    this.setState(
      {
        fleetReportOptions: {
          ...this.state.fleetReportOptions,
          type: 'email',
        },
      },
      () =>
        this.props.fetchExcelReportEmailData(
          this.state.selectedFleet,
          Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
          Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
          this.state.fleetReportOptions
        )
    );
  };

  emailWordReport = async () => {
    toast('Sending email...');
    const blob = await generateReportData(
      this.state.selectedFleet,
      this.state.reportName,
      this.state.selectedVehicle,
      this.state.selectedESS,
      Moment(this.state.startDate).format('DD.MM.YYYY'),
      Moment(this.state.endDate).format('DD.MM.YYYY'),
      vehicleCount,
      this.state.essCount,
      this.state.selectedPack,
      this.state.packCount,
      this.state.selectedPlatform
    );

    const formData = new FormData();
    const fileName = this.state.reportName + '.docx';
    const file = new File([blob], fileName);
    formData.append('uploadFile', file, fileName);
    formData.append('fileName', fileName);
    formData.append('fleetName', this.state.selectedFleet);
    formData.append('vehicleName', this.state.selectedVehicle);
    formData.append('essName', this.state.selectedESS);
    formData.append('packName', this.state.selectedPack);
    formData.append(
      'startDate',
      Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss')
    );
    formData.append(
      'endDate',
      Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss')
    );

    this.setState(
      {
        fleetReportOptions: {
          ...this.state.fleetReportOptions,
          type: 'emailword',
          uploadFile: formData,
        },
      },
      () =>
        this.props.fetchExcelReportEmailData(
          this.state.selectedFleet,
          Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
          Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
          this.state.fleetReportOptions
        )
    );
  };

  downloadExcelReport = () => {
    toast('Downloading report...');
    this.setState(
      {
        fleetReportOptions: {
          ...this.state.fleetReportOptions,
          type: 'download',
        },
      },
      () =>
        this.props.fetchExcelReportData(
          this.state.selectedFleet,
          Moment(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'),
          Moment(this.state.endDate).format('YYYY-MM-DDTHH:mm:ss'),
          this.state.fleetReportOptions
        )
    );
  };

  generateFleetWordReport = () => {
    // this.props.fleetWordReportData === null &&
    this.props.fetchFleetWordReportData(
      this.state.selectedFleet,
      this.state.selectedSla,
      this.state.fleetReportOptions
    );
  };

  downloadFleetWordReport = () => {
    generateFleetWordReport(
      this.props.fleetWordReportData,
      this.state.reportName
    );
  };

  emailFleetWordReport = () => {};

  eventList = () => {
    list_temparture = 0;
    list_crate = 0;
    list_soc = 0;
    list_cycle = 0;
    list_filecount = 0;
    this.props.eventList.forEach(item => {
      if (item.typ === 'Warning') {
        if (item.rsn === 'C-Rate') {
          list_crate++;
        }
        if (item.rsn === 'Temperature') {
          list_temparture++;
        }
        if (item.rsn === 'Cycles') {
          list_cycle++;
        }
        if (item.rsn === 'SOC') {
          list_soc++;
        }
        if (item.rsn === 'File Count') {
          list_filecount++;
        }
      }
    });
  };

  handleFleetClick = async fleet => {
    await this.props.clearChartData();

    const site = this.props.fleetsListData.filter(item => item.nm === fleet)[0]
      .st;
    const platform = this.props.fleetsListData.filter(
      item => item.nm === fleet
    )[0].pl;
    const sla = this.props.fleetsListData.filter(item => item.nm === fleet)[0]
      .fs;
    const vehicles = [];

    await this.props.getVehiclesList(site, fleet);

    this.props.vehiclesListData !== null &&
      this.props.vehiclesListData[0].vst.forEach(item => {
        vehicles.push(item.vn);
      });
    this.setState({
      selectedSite: site,
      selectedFleet: fleet,
      vehicleList: vehicles,
      selectedPlatform: platform,
      slaList: sla,
    });
    this.setState({
      selectedVehicle: null,
      selectedESS: null,
      selectedPack: null,
      selectedSla: null,
      essList: [],
      packList: [],
    });

    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('st', site);
      url.searchParams.set('ft', fleet);
      url.searchParams.delete('vc');
      url.searchParams.delete('pdn');
      url.searchParams.delete('pkdn');
      this.props.history.push(url.pathname + url.search);
    }
  };

  handleVehicleClick = vehicle => {
    const essdn = [];
    this.setState({
      selectedVehicle: vehicle,
      selectedESS: null,
      selectedPack: null,
      packList: [],
    });
    this.props.vehiclesListData[0].vst.forEach(item => {
      if (vehicle === item.vn) {
        item.vdat.forEach(ess => {
          if (ess.type !== 'converter') essdn.push(ess.dn);
        });
      }
    });
    this.setState({ essList: essdn }, () => this.enableCheck());

    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('vc', vehicle);
      url.searchParams.delete('pdn');
      url.searchParams.delete('pkdn');
      this.props.history.push(url.pathname + url.search);
    }
  };

  handleESSClick = ess => {
    const packdn = [];
    // this.setState({ selectedESS: ess });
    this.props.vehiclesListData[0].vst.forEach(item => {
      item.vdat.forEach(item_ess => {
        if (ess === item_ess.dn) {
          this.state.selectedPlatform === '3' &&
            item_ess.pdat.forEach(pack => {
              packdn.push(pack.dn);
            });

          this.setState(
            {
              selectedESS: ess,
              selectedESSan: item_ess.an,
              selectedESSip: item_ess.ip,
              packList: packdn,
              selectedPack: null,
            },
            () => this.enableCheck()
          );
          sessionStorage.setItem('selectedProductIP', item_ess.ip);
        }
      });
    });

    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('pdn', ess);
      url.searchParams.delete('pkdn');
      this.props.history.push(url.pathname + url.search);
    }
  };

  handlePackClick = pack => {
    this.props.vehiclesListData[0].vst.forEach(item => {
      item.vdat.forEach(item_ess => {
        if (item_ess.dn === this.state.selectedESS) {
          item_ess.pdat.forEach(item_pack => {
            if (pack === item_pack.dn) {
              this.setState(
                {
                  selectedPack: pack,
                  selectedPackan: item_pack.an,
                  selectedPackip: item_pack.ip,
                },
                () => this.enableCheck()
              );
              sessionStorage.setItem('selectedPack', item_pack.an);
            }
          });
        }
      });
    });

    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('pkdn', pack);
      this.props.history.push(url.pathname + url.search);
    }
  };

  handleReportSpecialChar = event => {
    const regex = /^[a-zA-Z0-9]+$/;
    const key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  handleReportNameFleetClick = e => {
    this.setState(
      {
        fleetReportOptions: {
          ...this.state.fleetReportOptions,
          reportName: e.target.value,
        },
      },
      () => this.enableCheck()
    );
  };

  handleReportNameEssClick = e => {
    this.setState(
      {
        reportName: e.target.value,
      },
      () => this.enableCheck()
    );
  };

  handleChkbxClick = e => {
    this.setState({
      fleetReportOptions: {
        ...this.state.fleetReportOptions,
        [e.target.id]: e.target.checked,
      },
    });
  };

  handleRadioBtnClick = e => {
    this.setState({
      fleetReportOptions: {
        ...this.state.fleetReportOptions,
        radio_Btn: e.target.id,
      },
    });
  };

  handleSlaClick = value => {
    this.setState({ selectedSla: value });
  };

  handleEmailClick = () => {
    this.setState({ emailSelection: !this.state.emailSelection });
  };

  enableCheck = () => {
    if (
      this.state.selectedVehicle !== null &&
      this.state.selectedESS !== null &&
      this.state.selectedESSan !== null &&
      this.state.reportName !== null &&
      this.state.reportName !== ''
    ) {
      this.setState({ enableValue: true });
    } else {
      this.setState({ enableValue: false });
    }
  };

  enableDownloadBtn = () => {
    if (
      this.props.usgPowHistogramData !== null &&
      this.props.usgDodHistogramData !== null
    ) {
      // this.setState({ enableDownloadBtn: true });
      return true;
    } else {
      return false;
    }
  };

  commonDate_onApply = (event, picker) => {
    const start = picker.startDate;
    const end = picker.endDate;
    let date1, time1, result;
    date1 = Moment(start).format('MM DD,YYYY');
    time1 = Moment(start).format('HH:mm:ss');
    result = date1 + ' ' + time1;
    result = new Date(result);
    this.setState({ startDate: result, startTime: result });
    date1 = Moment(end).format('MM DD,YYYY');
    time1 = Moment(end).format('HH:mm:ss');
    time1 = time1 === '00:00:00' ? '23:59:59' : time1;
    result = date1 + ' ' + time1;
    result = new Date(result);
    this.setState({ endDate: result, endTime: result });
    // this.props.applyButtonEnable(false);
    // this.applyDateValidation();
  };

  essPlatform1Preview = () => {
    return (
      <>
        <Col className="containerreportpreviewtitlebar">
          <div className="reportview-menubar">
            <div className="reportview-title">Report preview</div>
          </div>
        </Col>
        <div className="reportpreview">
          <div className="generatedreportcontainer">
            <h6>
              <b className="reddash">____</b>
              <br />
              <b>ABB TRACTION SERVICE</b>
            </h6>
            <h4>
              <b>{this.state.selectedFleet} Traction Batteries</b>
            </h4>
            <h4>
              ESS Condition Monitoring ESS Report
              <br />
              {Moment(this.state.startDate).format('DD.MM.YYYY') +
                ' - ' +
                Moment(this.state.endDate).format('DD.MM.YYYY')}
            </h4>
            <div className="reportmainimg">
              <img
                src={require('../../assets/images/report/report_main.jpg')}
                className="mainimg"
              />
            </div>
            <h4>
              <b>Contents</b>
            </h4>
            <h6>
              <ol type="1">
                <b>
                  <li>Purpose and Basic Description</li>
                  <li>Utilization ESS</li>
                </b>
                <ul type="square">
                  <li>Fleet Level</li>
                  {/* <ul type="disc">
                    <li>Overview KPIs </li>
                    <li>Data and Connectivity Health </li>
                  </ul> */}
                  <li>ESS Level</li>
                  <ul type="disc">
                    <li>Overview</li>
                    <li>{this.state.selectedESS}</li>
                  </ul>
                </ul>
              </ol>
            </h6>
            <h4>
              <b>1. Purpose and Basic Description</b>
            </h4>
            <div className="description">
              This report compiles the performance data and analysis of the{' '}
              {this.state.selectedFleet} fleet. This report comprises following
              configuration:
              <ul type="disc">
                <li>{vehicleCount} Vehicles</li>
                <li>{this.state.essCount} Energy Storage Systems</li>
              </ul>
              The fleet’s performance assessment is deducted and evaluated from
              the data sources as illustrated in Figure 1:
              <div className="reportcondmonitorimg">
                <img
                  src={require('../../assets/images/report/condmonitor.png')}
                  className="condmonitorimg"
                />
              </div>
            </div>
            <h4>
              <b>2. Utilization ESS</b>
            </h4>
            <div className="description">
              This chapter displays the utilization of the traction batteries on
              a fleet level within the report period providing information on
              the usage and operational pattern of the systems through several
              KPIs subjected to history track and trend development.
            </div>
            <h5>
              <b>2.1. Fleet Level</b>
            </h5>
            <div className="description">
              The fleet counts on {vehicleCount} vehicles fitting{' '}
              {this.state.essCount} Energy Storage Systems (ESS).
            </div>
            <div className="reportview-fleet" id="overviewreport">
              <div className="producttitle">{'Overview'}</div>
              {checkUserEntitlements('ESS:Fleet-Overview') &&
                (this.props.loadingVehicles === true ||
                this.props.vehiclesListData === null ? (
                  <Loading loadintText="Loading data" />
                ) : this.props.vehiclesListData &&
                  this.props.vehiclesListData[0].vst.length > 0 &&
                  this.props.vehiclesListData &&
                  this.props.vehiclesListData[0].data.length > 0 ? (
                  <Overview
                    id="reportimg"
                    overviewData={this.props.overviewData}
                    onVehicleListClick={this.props.onVehicleListClick}
                    vehiclesListData={this.props.vehiclesListData}
                    listSelection={this.props.listSelection}
                    filterItems={this.state.filterItems}
                    userConfig={userConfig}
                    sideMenuFilter={this.props.sideMenuFilter}
                    filterAvailability={this.props.filterAvailability}
                    filterReliability={this.props.filterReliability}
                    filterUtilization={this.props.filterUtilization}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                ))}
            </div>
            {/* <h5>
              <b>2.1.1. Overview KPIs</b>
            </h5>
            <div className="description">
              Table 1 reflected in this chapter contains the key performance
              indicators applied to the fleet cumulated over the report period.
              <br />
              <br />
              Table 1: Global ESS Fleet KPIs
              <div style={{ "text-align": "-webkit-center" }}>
                <table className="fleetkpitable">
                  <tr>
                    <th style={{ width: "65%" }}>Measure</th>
                    <th style={{ width: "20%" }}>Value</th>
                    <th style={{ width: "20%" }}>Unit</th>
                  </tr>
                  <tr>
                    <td>
                      Number of operational ESS in fleet during reporting period
                    </td>
                    <td></td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Number of registered ESS in fleet</td>
                    <td></td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Nominal energy throughput per ESS</td>
                    <td></td>
                    <td>kWh/Day</td>
                  </tr>
                  <tr>
                    <td>Average fleet throughput during reporting period</td>
                    <td></td>
                    <td>kWh/Day</td>
                  </tr>
                  <tr>
                    <td>State of Health - ESS average</td>
                    <td></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>
                      State of Health – minimal
                      <br />
                      ESS and vehicle name
                    </td>
                    <td></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>
                      State of Health – maximal
                      <br />
                      ESS and vehicle name
                    </td>
                    <td></td>
                    <td>%</td>
                  </tr>
                </table>
              </div>
            </div> */}
            <h5>
              <b>2.2. ESS Level</b>
            </h5>
            <h6>
              <b>2.2.1. Overview</b>
            </h6>
            <div className="description">
              The following batteries in Table 2 correspond to the selected
              choice:
              <br />
              Table 2: overview of selected batteries
              <div style={{ 'text-align': '-webkit-center' }}>
                <table className="fleetkpitable">
                  <tr>
                    <th>#</th>
                    <th style={{ width: '25%' }}>Battery</th>
                    <th style={{ width: '25%' }}>Vehicle</th>
                    <th style={{ width: '68%' }}>Description</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>{this.state.selectedESS}</td>
                    <td>{this.state.selectedVehicle}</td>
                    <td>-</td>
                  </tr>
                </table>
              </div>
            </div>
            <h6>
              <b>2.2.2. {this.state.selectedESS}</b>
            </h6>
            <h6>
              <b>2.2.2.1. Reliability</b>
            </h6>

            <div
              className="reportview-productreliability"
              id="product_reliability">
              {/* {this.props.batteryRelData !== null && (
                <StockGraph
                  chartType="BATT_REL_COL_TYPE"
                  data={this.props.batteryRelData[0]}
                  //redirectToTimetrend={this.props.redirectToTimetrend}
                  report={true}
                />
              )} */}
              {this.props.batteryRelData.length > 0 ? (
                <Row>
                  <Col md={12} className="noLeftpadding noRightpadding">
                    <div className="stsinfo">
                      <span className="txt">
                        {this.props.batteryRelData[0].sts === 'Reliable' ? (
                          <span id="ok"> Reliable </span>
                        ) : this.props.batteryRelData[0].sts ===
                          'Not reliable' ? (
                          <span id="notok"> Not reliable </span>
                        ) : (
                          <span id="unknown"> Unknown </span>
                        )}
                      </span>
                      <span className="util-info-smalltxt">
                        Accumulated availability status over &nbsp;
                        {this.state.selectedFleet === 'ABB ESS Demo Fleet'
                          ? '30 days '
                          : 'the last 30 days '}{' '}
                        (Click on the section of stacked bar chart for details)
                      </span>
                      <br />
                      <span className="util-info-txt formulatext">
                        {this.props.batteryRelData[0].sts === 'Reliable' ? (
                          <span>
                            <span>Hours in error state</span>
                            <span className="dividesymbol"> &#247;</span>
                            <span> total operation time</span>{' '}
                            <span>
                              {' '}
                              &lt; {this.props.batteryRelData[0].ctv} %
                            </span>
                          </span>
                        ) : this.props.batteryRelData[0].sts ===
                          'Not reliable' ? (
                          <span>
                            <span>Hours in error state</span>
                            <span className="dividesymbol"> &#247;</span>
                            <span> total operation time</span>{' '}
                            <span>
                              {' '}
                              &gt;{this.props.batteryRelData[0].ctv} %
                            </span>
                          </span>
                        ) : (
                          ''
                        )}
                      </span>{' '}
                    </div>
                  </Col>
                  <Col md={4} className="noLeftpadding noRightpadding">
                    <PieGraph
                      chartType="BATT_REL_PIE_TYPE"
                      data={this.props.batteryRelData[0].pie}
                      // pieChartSelected={this.pieChartSelected}
                    />
                  </Col>
                  <Col md={8} className="noLeftpadding noRightpadding">
                    {
                      <StockGraph
                        chartType="BATT_REL_COL_TYPE"
                        data={this.props.batteryRelData[0]}
                        // redirectToTimetrend={this.props.redirectToTimetrend}
                        report={true}
                      />
                    }
                  </Col>
                </Row>
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>

            <h6>
              <b>2.2.2.2. Utilization</b>
            </h6>

            <div
              className="reportview-productreliability"
              id="product_utilization">
              {this.props.batteryStrData.length > 0 ? (
                <Row>
                  <Col md={12} className="noLeftpadding noRightpadding">
                    <div className="stsinfo">
                      <span className="txt">
                        {this.props.batteryStrData[0].sts === 'Normal' ? (
                          <span id="ok"> Normal utilization </span>
                        ) : this.props.batteryStrData[0].sts ===
                          'Above normal' ? (
                          <span id="notok"> Above normal utilization </span>
                        ) : (
                          <span id="unknown"> Unknown </span>
                        )}
                      </span>
                      <span className="util-info-smalltxt">
                        {this.state.selectedFleet === 'ABB ESS Demo Fleet'
                          ? ' 30 days '
                          : ' last 30 days '}
                        (Click on the section of stacked bar chart for details){' '}
                        <br />
                      </span>
                      {this.props.batteryStrData[0].sts === 'Normal' ? (
                        <Row className="util-info-txt formulatext">
                          <Col md={12} className="noLeftpadding ">
                            <span>Hours above normal utilization</span>
                            <span className="dividesymbol"> &#247;</span>
                            <span> total operation time</span>{' '}
                            <span>
                              &lt; {this.props.batteryStrData[0].ctv.ctv} %
                            </span>
                          </Col>
                          {/* <Col
                            md={3}
                            className="noLeftpadding noRightpadding d-flex align-items-center"
                          >
                            &lt; 25%
                          </Col> */}
                        </Row>
                      ) : this.props.batteryStrData[0].sts ===
                        'Above normal' ? (
                        <Row className="util-info-txt formulatext">
                          <Col md={12} className="noLeftpadding ">
                            <span>Hours above normal utilization</span>
                            <span className="dividesymbol"> &#247;</span>
                            <span> total operation time</span>
                            <span>
                              {' '}
                              &gt; {this.props.batteryStrData[0].ctv.ctv} %
                            </span>
                          </Col>
                          {/* <Col
                            md={3}
                            className="noLeftpadding noRightpadding d-flex align-items-center"
                          >
                            &gt; 25%
                          </Col> */}
                        </Row>
                      ) : (
                        <span className="util-info-txt">
                          {' '}
                          Utilization indicates unknown when the relavent data
                          is not available.
                          <br />{' '}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col md={4} className="noLeftpadding noRightpadding">
                    <PieGraph
                      chartType="BATT_STR_PIE_TYPE"
                      data={this.props.batteryStrData[0].pie}
                      // pieChartSelected={this.pieChartSelected}
                    />
                  </Col>
                  <Col md={8} className="noLeftpadding noRightpadding">
                    <StockGraph
                      chartType="BATT_STR_COL_TYPE"
                      data={this.props.batteryStrData[0]}
                      // redirectToTimetrend={this.props.redirectToTimetrend}
                      report={true}
                    />
                  </Col>
                  <div className="threstable">
                    {' '}
                    <UtilizationThresholdInfo
                      stressData={this.props.batteryStrData[0].ctv}
                    />
                  </div>
                </Row>
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
            <h6>
              <b>2.2.2.3. Operating hours</b>
            </h6>
            <div className="description">
              {/* <div className="producttitle">{"2.2.2.4.	Operating hours"}</div> */}
              <div className="reportview-product" id="operatinghoursreport">
                {this.props.operatingHoursHistogramData !== null &&
                this.props.operatingHoursHistogramData.rp.length > 0 ? (
                  <SingleGraph
                    chartType="OPERATING_HOURS_HISTOGRAM_TYPE"
                    data={this.props.operatingHoursHistogramData.rp}
                    // activeTab={"usgpow"}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.2.4. Temperature</b>
            </h6>
            <div className="description">
              {/* <div className="producttitle">{"Temperature"}</div> */}
              <div className="reportview-product" id="temperaturereport">
                {this.props.temperatureHistogramData !== null &&
                this.props.temperatureHistogramData.length > 0 ? (
                  <SingleGraph
                    chartType="DESIGNED_TYPE"
                    data={this.props.temperatureHistogramData}
                    activeTab={'usgpow'}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.2.5. State of Charge</b>
            </h6>
            <div className="description">
              <div className="reportview-product" id="socreport">
                {this.props.usgSocHistogramData !== null &&
                this.props.usgSocHistogramData.length > 0 ? (
                  <SingleGraph
                    chartType="DESIGNED_TYPE"
                    data={this.props.usgSocHistogramData}
                    activeTab={'usgpow'}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.2.6. Power</b>
            </h6>
            <div className="description">
              <div className="reportview-product" id="powerreport">
                {this.props.usgPowHistogramData !== null &&
                this.props.usgPowHistogramData.length > 0 ? (
                  <SingleGraph
                    chartType="DESIGNED_TYPE"
                    data={this.props.usgPowHistogramData}
                    activeTab={'usgpow'}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.2.7. Depth of discharge</b>
            </h6>
            <div className="description">
              <div className="reportview-product" id="dodreport">
                {this.props.usgDodHistogramData !== null &&
                this.props.usgDodHistogramData.length > 0 ? (
                  <SingleGraph
                    chartType="DESIGNED_TYPE"
                    data={this.props.usgDodHistogramData}
                    activeTab={'usgpow'}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.2.8. Event List</b>
            </h6>
            <div className="description">
              <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
                <table className="fleetkpitable">
                  <tr>
                    <th style={{ width: '15%' }}>Type</th>
                    <th style={{ width: '20%' }}>Reason</th>
                    <th style={{ width: '35%' }}>Message</th>
                    <th style={{ width: '30%', textAlign: 'center' }}>
                      # Events
                    </th>
                  </tr>
                  {list_temparture > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>Temperature</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_temparture}</td>
                    </tr>
                  )}
                  {list_crate > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>C-Rate</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_crate}</td>
                    </tr>
                  )}
                  {list_cycle > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>Cycles</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_cycle}</td>
                    </tr>
                  )}
                  {list_soc > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>SOC</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_soc}</td>
                    </tr>
                  )}
                  {list_filecount > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>File Count</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_filecount}</td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  essPlatform3Preview = () => {
    return (
      <>
        <Col className="containerreportpreviewtitlebar">
          <div className="reportview-menubar">
            <div className="reportview-title">Report preview</div>
          </div>
        </Col>
        <div className="reportpreview">
          <div className="generatedreportcontainer">
            <h6>
              <b className="reddash">____</b>
              <br />
              <b>ABB TRACTION SERVICE</b>
            </h6>
            <h4>
              <b>{this.state.selectedFleet} Traction Batteries</b>
            </h4>
            <h4>
              Condition Monitoring Product Report
              <br />
              {Moment(this.state.startDate).format('DD.MM.YYYY') +
                ' - ' +
                Moment(this.state.endDate).format('DD.MM.YYYY')}
            </h4>
            <div className="reportmainimg">
              <img
                src={require('../../assets/images/report/report_main.jpg')}
                className="mainimg"
              />
            </div>
            <h4>
              <b>Contents</b>
            </h4>
            <h6>
              <ol type="1">
                <b>
                  <li>Purpose and Basic Description</li>
                  <li>Utilization of product</li>
                </b>
                <ul type="square">
                  <li>Fleet Level</li>
                  {/* <ul type="disc">
                    <li>Overview KPIs </li>
                    <li>Data and Connectivity Health </li>
                  </ul> */}
                  <li>Product Level</li>
                  <ul type="disc">
                    <li>Overview</li>
                    <li>
                      {this.state.selectedPack}({this.state.selectedESS})
                    </li>
                  </ul>
                </ul>
              </ol>
            </h6>
            <h4>
              <b>1. Purpose and Basic Description</b>
            </h4>
            <div className="description">
              This report compiles the performance data and analysis of the{' '}
              {this.state.selectedFleet} fleet. This report comprises following
              configuration:
              <ul type="disc">
                <li>{vehicleCount} Vehicles</li>
                <li>{this.state.essCount} Battery Systems</li>
                <li>{this.state.packCount} Battery Packs</li>
              </ul>
              The fleet’s performance assessment is deducted and evaluated from
              the data sources as illustrated in Figure 1:
              <div className="reportcondmonitorimg">
                <img
                  src={require('../../assets/images/report/condmonitor.png')}
                  className="condmonitorimg"
                />
              </div>
            </div>
            <h4>
              <b>2. Utilization ESS</b>
            </h4>
            <div className="description">
              This chapter displays the utilization of the products on a fleet
              level within the report period providing information on the usage
              and operational pattern of the systems through several KPIs
              subjected to history track and trend development.
            </div>
            <h5>
              <b>2.1. Fleet Level</b>
            </h5>
            <div className="description">
              The fleet counts {vehicleCount} vehicles fitting{' '}
              {this.state.essCount} battery systems (total:{' '}
              {this.state.packCount} battery packs).
            </div>
            <div className="reportview-fleet" id="overviewreport">
              <div className="producttitle">{'Overview'}</div>
              {checkUserEntitlements('ESS:Fleet-Overview') &&
                (this.props.loadingVehicles === true ||
                this.props.vehiclesListData === null ? (
                  <Loading loadintText="Loading data" />
                ) : this.props.vehiclesListData &&
                  this.props.vehiclesListData[0].vst.length > 0 &&
                  this.props.vehiclesListData &&
                  this.props.vehiclesListData[0].data.length > 0 ? (
                  <Overview
                    id="reportimg"
                    overviewData={this.props.overviewData}
                    onVehicleListClick={this.props.onVehicleListClick}
                    vehiclesListData={this.props.vehiclesListData}
                    listSelection={this.props.listSelection}
                    filterItems={this.state.filterItems}
                    userConfig={userConfig}
                    sideMenuFilter={this.props.sideMenuFilter}
                    filterAvailability={this.props.filterAvailability}
                    filterReliability={this.props.filterReliability}
                    filterUtilization={this.props.filterUtilization}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                ))}
            </div>
            {/* <h5>
              <b>2.1.1. Overview KPIs</b>
            </h5>
            <div className="description">
              Table 1 reflected in this chapter contains the key performance
              indicators applied to the fleet cumulated over the report period.
              <br />
              <br />
              Table 1: Global Product Fleet KPIs
              <div style={{ "text-align": "-webkit-center" }}>
                <table className="fleetkpitable">
                  <tr>
                    <th style={{ width: "65%" }}>Measure</th>
                    <th style={{ width: "20%" }}>Value</th>
                    <th style={{ width: "20%" }}>Unit</th>
                  </tr>
                  <tr>
                    <td>
                      Number of operational Products in fleet during reporting
                      period
                    </td>
                    <td></td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Number of registered Products in fleet</td>
                    <td></td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Nominal energy throughput per Product</td>
                    <td></td>
                    <td>kWh/Day</td>
                  </tr>
                  <tr>
                    <td>Average fleet throughput during reporting period</td>
                    <td></td>
                    <td>kWh/Day</td>
                  </tr>
                  <tr>
                    <td>State of Health - Product average</td>
                    <td></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>
                      State of Health – minimal
                      <br />
                      Product and vehicle name
                    </td>
                    <td></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>
                      State of Health – maximal
                      <br />
                      Product and vehicle name
                    </td>
                    <td></td>
                    <td>%</td>
                  </tr>
                </table>
              </div>
            </div> */}
            <h5>
              <b>2.2. Product Level</b>
            </h5>
            <h6>
              <b>2.2.1. Overview</b>
            </h6>
            <div className="description">
              The following products in Table 2 correspond to the selected
              choice:
              <br />
              Table 2: overview of selected batteries
              <div style={{ 'text-align': '-webkit-center' }}>
                <table className="fleetkpitable">
                  <tr>
                    <th>#</th>
                    <th style={{ width: '25%' }}>Product</th>
                    <th style={{ width: '25%' }}>Vehicle</th>
                    <th style={{ width: '68%' }}>Description</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>{this.state.selectedPack}</td>
                    <td>{this.state.selectedVehicle}</td>
                    <td>-</td>
                  </tr>
                </table>
              </div>
            </div>
            <h6>
              <b>
                {this.state.selectedPack}({this.state.selectedESS})
              </b>
            </h6>
            <h6>
              <b>2.2.1.1. Reliability</b>
            </h6>

            <div
              className="reportview-productreliability"
              id="product_reliability">
              {/* {this.props.batteryRelData !== null && (
                <StockGraph
                  chartType="BATT_REL_COL_TYPE"
                  data={this.props.batteryRelData[0]}
                  //redirectToTimetrend={this.props.redirectToTimetrend}
                  report={true}
                />
              )} */}
              {this.props.batteryRelData.length > 0 ? (
                <Row>
                  <Col md={12} className="noLeftpadding noRightpadding">
                    <div className="stsinfo">
                      <span className="txt">
                        {this.props.batteryRelData[0].sts === 'Reliable' ? (
                          <span id="ok"> Reliable </span>
                        ) : this.props.batteryRelData[0].sts ===
                          'Not reliable' ? (
                          <span id="notok"> Not reliable </span>
                        ) : (
                          <span id="unknown"> Unknown </span>
                        )}
                      </span>
                      <span className="util-info-smalltxt">
                        Accumulated availability status over &nbsp;
                        {this.state.selectedFleet === 'ABB ESS Demo Fleet'
                          ? '30 days '
                          : 'the last 30 days '}{' '}
                        (Click on the section of stacked bar chart for details)
                      </span>
                      <br />
                      <span className="util-info-txt formulatext">
                        {this.props.batteryRelData[0].sts === 'Reliable' ? (
                          <span>
                            <span>Hours in error state</span>
                            <span className="dividesymbol"> &#247;</span>
                            <span> total operation time</span>{' '}
                            <span>
                              {' '}
                              &lt; {this.props.batteryRelData[0].ctv} %
                            </span>
                          </span>
                        ) : this.props.batteryRelData[0].sts ===
                          'Not reliable' ? (
                          <span>
                            <span>Hours in error state</span>
                            <span className="dividesymbol"> &#247;</span>
                            <span> total operation time</span>{' '}
                            <span>
                              {' '}
                              &gt;{this.props.batteryRelData[0].ctv} %
                            </span>
                          </span>
                        ) : (
                          ''
                        )}
                      </span>{' '}
                    </div>
                  </Col>
                  <Col md={4} className="noLeftpadding noRightpadding">
                    <PieGraph
                      chartType="BATT_REL_PIE_TYPE"
                      data={this.props.batteryRelData[0].pie}
                      // pieChartSelected={this.pieChartSelected}
                    />
                  </Col>
                  <Col md={8} className="noLeftpadding noRightpadding">
                    {
                      <StockGraph
                        chartType="BATT_REL_COL_TYPE"
                        data={this.props.batteryRelData[0]}
                        // redirectToTimetrend={this.props.redirectToTimetrend}
                        report={true}
                      />
                    }
                  </Col>
                </Row>
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>

            <h6>
              <b>2.2.1.2. Utilization</b>
            </h6>

            <div
              className="reportview-productreliability"
              id="product_utilization">
              {this.props.batteryStrData.length > 0 ? (
                <Row>
                  <Col md={12} className="noLeftpadding noRightpadding">
                    <div className="stsinfo">
                      <span className="txt">
                        {this.props.batteryStrData[0].sts === 'Normal' ? (
                          <span id="ok"> Normal utilization </span>
                        ) : this.props.batteryStrData[0].sts ===
                          'Above normal' ? (
                          <span id="notok"> Above normal utilization </span>
                        ) : (
                          <span id="unknown"> Unknown </span>
                        )}
                      </span>
                      <span className="util-info-smalltxt">
                        {this.state.selectedFleet === 'ABB ESS Demo Fleet'
                          ? ' 30 days '
                          : ' last 30 days '}
                        (Click on the section of stacked bar chart for details){' '}
                        <br />
                      </span>
                      {this.props.batteryStrData[0].sts === 'Normal' ? (
                        <Row className="util-info-txt formulatext">
                          <Col md={12} className="noLeftpadding ">
                            <span>Hours above normal utilization</span>
                            <span className="dividesymbol"> &#247;</span>
                            <span> total operation time</span>{' '}
                            <span>
                              &lt; {this.props.batteryStrData[0].ctv.ctv} %
                            </span>
                          </Col>
                          {/* <Col
                            md={3}
                            className="noLeftpadding noRightpadding d-flex align-items-center"
                          >
                            &lt; 25%
                          </Col> */}
                        </Row>
                      ) : this.props.batteryStrData[0].sts ===
                        'Above normal' ? (
                        <Row className="util-info-txt formulatext">
                          <Col md={12} className="noLeftpadding ">
                            <span>Hours above normal utilization</span>
                            <span className="dividesymbol"> &#247;</span>
                            <span> total operation time</span>
                            <span>
                              {' '}
                              &gt; {this.props.batteryStrData[0].ctv.ctv} %
                            </span>
                          </Col>
                          {/* <Col
                            md={3}
                            className="noLeftpadding noRightpadding d-flex align-items-center"
                          >
                            &gt; 25%
                          </Col> */}
                        </Row>
                      ) : (
                        <span className="util-info-txt">
                          {' '}
                          Utilization indicates unknown when the relavent data
                          is not available.
                          <br />{' '}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col md={4} className="noLeftpadding noRightpadding">
                    <PieGraph
                      chartType="BATT_STR_PIE_TYPE"
                      data={this.props.batteryStrData[0].pie}
                      // pieChartSelected={this.pieChartSelected}
                    />
                  </Col>
                  <Col md={8} className="noLeftpadding noRightpadding">
                    <StockGraph
                      chartType="BATT_STR_COL_TYPE"
                      data={this.props.batteryStrData[0]}
                      // redirectToTimetrend={this.props.redirectToTimetrend}
                      report={true}
                    />
                  </Col>
                  <div className="threstable">
                    {' '}
                    <UtilizationThresholdInfo
                      stressData={this.props.batteryStrData[0].ctv}
                    />
                  </div>
                </Row>
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
            <h6>
              <b>2.2.1.3. Operating hours</b>
            </h6>
            <div className="description">
              {/* <div className="producttitle">{"2.2.2.4.	Operating hours"}</div> */}
              <div className="reportview-product" id="operatinghoursreport">
                {this.props.operatingHoursHistogramData !== null &&
                this.props.operatingHoursHistogramData.rp.length > 0 ? (
                  <SingleGraph
                    chartType="OPERATING_HOURS_HISTOGRAM_TYPE"
                    data={this.props.operatingHoursHistogramData.rp}
                    // activeTab={"usgpow"}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.1.4. Temperature</b>
            </h6>
            <div className="description">
              {/* <div className="producttitle">{"Temperature"}</div> */}
              <div className="reportview-product" id="temperaturereport">
                {this.props.temperatureHistogramData !== null &&
                this.props.temperatureHistogramData.length > 0 ? (
                  <SingleGraph
                    chartType="DESIGNED_TYPE"
                    data={this.props.temperatureHistogramData}
                    activeTab={'usgpow'}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.1.5. State of Charge</b>
            </h6>
            <div className="description">
              <div className="reportview-product" id="socreport">
                {this.props.usgSocHistogramData !== null &&
                this.props.usgSocHistogramData.length > 0 ? (
                  <SingleGraph
                    chartType="DESIGNED_TYPE"
                    data={this.props.usgSocHistogramData}
                    activeTab={'usgpow'}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.1.6. Power</b>
            </h6>
            <div className="description">
              <div className="reportview-product" id="powerreport">
                {this.props.usgPowHistogramData !== null &&
                this.props.usgPowHistogramData.length > 0 ? (
                  <SingleGraph
                    chartType="DESIGNED_TYPE"
                    data={this.props.usgPowHistogramData}
                    activeTab={'usgpow'}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.1.7. Depth of discharge</b>
            </h6>
            <div className="description">
              <div className="reportview-product" id="dodreport">
                {this.props.usgDodHistogramData !== null &&
                this.props.usgDodHistogramData.length > 0 ? (
                  <SingleGraph
                    chartType="DESIGNED_TYPE"
                    data={this.props.usgDodHistogramData}
                    activeTab={'usgpow'}
                    expandSide={true}
                    sideWidth={370}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </div>
            <h6>
              <b>2.2.1.8. Event List</b>
            </h6>
            <div className="description">
              <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
                <table className="fleetkpitable">
                  <tr>
                    <th style={{ width: '15%' }}>Type</th>
                    <th style={{ width: '20%' }}>Reason</th>
                    <th style={{ width: '35%' }}>Message</th>
                    <th style={{ width: '30%', textAlign: 'center' }}>
                      # Events
                    </th>
                  </tr>
                  {list_temparture > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>Temperature</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_temparture}</td>
                    </tr>
                  )}
                  {list_crate > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>C-Rate</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_crate}</td>
                    </tr>
                  )}
                  {list_cycle > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>Cycles</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_cycle}</td>
                    </tr>
                  )}
                  {list_soc > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>SOC</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_soc}</td>
                    </tr>
                  )}
                  {list_filecount > 0 && (
                    <tr>
                      <td>Warning</td>
                      <td>File Count</td>
                      <td>Above normal utilization detected</td>
                      <td style={{ textAlign: 'center' }}>{list_filecount}</td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    this.props.eventList !== null && this.eventList();
    isValid = this.checkDateValidation();
    const { selectedFleet, selectedVehicle, selectedESS } = this.state;
    const dataInValid = !!(
      selectedFleet === null ||
      selectedVehicle === null ||
      selectedESS === null
    );

    return this.state.isAccessReport ? (
      <React.Fragment className="containerreport">
        <Breadcrumb
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          MonitoredCount={this.props.monitoredCountData}
        />
        <Row className="reportcontainer">
          <Col md={3} className="selectionpart">
            <div className="reportconfig">Report configuration</div>
            <Tabs
              id="reporttabs"
              activeKey={this.state.selectedTab}
              onSelect={k => this.setState({ selectedTab: k })}>
              <Tab
                eventKey="fleetreport"
                title={
                  <span className="tabIcon">
                    <span className="tabtitle" id="fleetreport">
                      Fleet report
                    </span>
                  </span>
                }>
                <Col md={12} className="fleetselection">
                  <div className="selectfleet">{'Fleet'}</div>
                  <DropdownList
                    data={this.state.fleetList}
                    value={this.state.selectedFleet}
                    className="dropdownfleetlist"
                    placeholder="Select Fleet"
                    onChange={this.handleFleetClick}
                    busy={this.state.fleetList === null}
                    selectIcon={<CaretDown />}
                  />
                  {/* <Form.Control
                    //className="searchall"
                    //required
                    //autoComplete="off"
                    type="text"
                    value={sessionStorage.getItem("selectedFleet")}
                    className="fleettitle"
                    disabled
                  /> */}
                </Col>
                <Col md={12} className="fleetselection">
                  <div className="selectfleet">Report title</div>
                  <Form.Control
                    // required
                    // autoComplete="off"
                    type="text"
                    placeholder=""
                    className="fleettitle"
                    value={this.state.fleetReportOptions.reportName}
                    onChange={this.handleReportNameFleetClick}
                    onKeyPress={this.handleReportSpecialChar}
                  />
                </Col>
                <div className="groupesschkbox">
                  <div className="selectionesschkbox">
                    <input
                      id="r_word"
                      type="radio"
                      name="reportfile_selection"
                      defaultChecked
                      // checked={this.state.emailSelection}
                      onChange={this.handleRadioBtnClick}
                      className="ckbx"
                    />
                    <label className="ckbx_label" htmlFor="m_stress">
                      Word file report
                    </label>
                  </div>
                  <div className="selectionesschkbox">
                    <input
                      id="r_excel"
                      type="radio"
                      name="reportfile_selection"
                      // checked={this.state.emailSelection}
                      onChange={this.handleRadioBtnClick}
                      className="ckbx"
                    />
                    <label className="ckbx_label" htmlFor="l_reliable">
                      Excel report
                    </label>
                  </div>
                </div>
                {this.state.fleetReportOptions.radio_Btn === 'r_excel' ? (
                  <>
                    <div className="groupesschkbox">
                      <div className="selectionesschkbox">
                        <input
                          id="m_stress"
                          type="checkbox"
                          // checked={this.state.emailSelection}
                          onChange={this.handleChkbxClick}
                          className="ckbx"
                        />
                        <label className="ckbx_label" htmlFor="m_stress">
                          Add the 10 most stressed ESS
                        </label>
                      </div>
                      <div className="selectionesschkbox">
                        <input
                          id="l_reliable"
                          type="checkbox"
                          // checked={this.state.emailSelection}
                          onChange={this.handleChkbxClick}
                          className="ckbx"
                        />
                        <label className="ckbx_label" htmlFor="l_reliable">
                          Add the 10 least reliable ESS
                        </label>
                      </div>
                      <div className="selectionesschkbox">
                        <input
                          id="m_enrgy"
                          type="checkbox"
                          // checked={this.state.emailSelection}
                          onChange={this.handleChkbxClick}
                          className="ckbx"
                        />
                        <label className="ckbx_label" htmlFor="m_enrgy">
                          Add the 5 ESS with most energy throughputs
                        </label>
                      </div>
                      <div className="selectionesschkbox">
                        <input
                          id="l_enrgy"
                          type="checkbox"
                          // checked={this.state.emailSelection}
                          onChange={this.handleChkbxClick}
                          className="ckbx"
                        />
                        <label className="ckbx_label" htmlFor="l_enrgy">
                          Add the 5 ESS with least energy throughputs
                        </label>
                      </div>
                    </div>

                    <Col md={12} className="datePickerTextbox">
                      <div className="selectfleet">{'Report period'}</div>

                      <DateRangePicker
                        ref={this.datepickerRef}
                        onApply={this.commonDate_onApply}
                        // onCallback={this.commonDate_callback}
                        initialSettings={{
                          showDropdowns: true,
                          maxDate: Moment(
                            new Date(Moment().subtract(1, 'days').endOf('day'))
                          ).format('MM/DD/YYYY'),
                        }}>
                        <span className="timevalue">
                          <input
                            value={
                              Moment(this.state.startDate).format(
                                'DD.MM.YYYY'
                              ) +
                              ' - ' +
                              Moment(this.state.endDate).format('DD.MM.YYYY')
                            }
                            className="datepickervalue"
                            readOnly
                          />
                        </span>
                      </DateRangePicker>
                      <div className="error-message-time">
                        {!isValid ? (
                          <FormattedMessage {...messages.problem} />
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col md={12} className="fleetselection">
                    <div className="selectfleet">
                      {'Starting date of the report'}
                    </div>

                    <DropdownList
                      data={this.state.slaList}
                      value={this.state.selectedSla}
                      className="dropdownfleetlist"
                      placeholder=""
                      onChange={this.handleSlaClick}
                      // busy={this.state.fleetList === null}
                      selectIcon={<CaretDown />}
                    />
                  </Col>
                )}
                <button
                  onClick={
                    this.state.fleetReportOptions.radio_Btn === 'r_word'
                      ? this.generateFleetWordReport
                      : this.generateExcelReport
                  }
                  type="button"
                  className="btn  btn-sm buttongenerate"
                  disabled={
                    selectedFleet === null ||
                    (this.state.fleetReportOptions.radio_Btn === 'r_word'
                      ? this.state.selectedSla === null
                      : false)
                  } // {!this.state.enableValue}
                >
                  Generate
                </button>
                {/* <button
              onClick={() =>
                generateReport(
                  this.state.reportName,
                  this.state.selectedVehicle,
                  this.state.selectedESS,
                  Moment(this.state.startDate).format("DD.MM.YYYY"),
                  Moment(this.state.endDate).format("DD.MM.YYYY"),
                  vehicleCount,
                  essCount,
                  this.state.emailSelection
                )
              }
              type="button"
              className="btn  btn-sm buttondownload"
              disabled={!this.enableDownloadBtn()}
            >
              Download
            </button> */}
                <DropdownButton
                  id="buttondownload"
                  title="Download"
                  // type="button"
                  variant=""
                  className="btn  btn-sm buttondownload"
                  disabled={
                    this.state.selectedFleet === null ||
                    (this.state.fleetReportOptions.radio_Btn === 'r_word'
                      ? this.state.selectedSla === null
                      : this.props.excelReportData === null)
                  }
                  // disabled={!this.enableDownloadBtn()}
                  // onClick={this.downloadExcelReport}
                  onClick={e => {
                    if (e.target.name === 'excel') {
                      this.downloadExcelReport();
                    } else if (e.target.name === 'word') {
                      this.downloadFleetWordReport();
                    }
                  }}>
                  {this.state.fleetReportOptions.radio_Btn === 'r_word' ? (
                    <Dropdown.Item name="word" eventKey="1" as="button">
                      Word
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item name="excel" eventKey="1" as="button">
                      Excel
                    </Dropdown.Item>
                  )}
                </DropdownButton>
                <button
                  onClick={
                    this.state.fleetReportOptions.radio_Btn === 'r_word'
                      ? this.emailFleetWordReport
                      : this.emailExcelReport
                  }
                  type="button"
                  className="btn  btn-sm buttonemail"
                  disabled={this.state.selectedFleet === null || !isValid} // {!this.enableDownloadBtn()}
                >
                  Send via email
                </button>
              </Tab>
              <Tab
                eventKey="essreport"
                title={
                  <span className="tabIcon">
                    <span className="tabtitle" id="essreport">
                      ESS report
                    </span>
                  </span>
                }>
                <Col md={12} className="fleetselection">
                  <div className="selectfleet">{'Fleet'}</div>

                  <DropdownList
                    data={this.state.fleetList}
                    value={this.state.selectedFleet}
                    className="dropdownfleetlist"
                    placeholder="Select Fleet"
                    onChange={this.handleFleetClick}
                    busy={this.state.fleetList === null}
                    selectIcon={<CaretDown />}
                  />
                  {/* <Form.Control
                    //className="searchall"
                    //required
                    //autoComplete="off"
                    type="text"
                    value={sessionStorage.getItem("selectedFleet")}
                    className="fleettitle"
                    disabled
                  /> */}
                </Col>
                <Col md={12} className="fleetselection">
                  <div className="selectfleet">{'Vehicle'}</div>
                  <DropdownList
                    data={this.state.vehicleList}
                    value={this.state.selectedVehicle}
                    className="dropdownfleetlist"
                    placeholder="Select Vehicle"
                    onChange={this.handleVehicleClick}
                    busy={this.props.loadingVehicles === true}
                    selectIcon={<CaretDown />}
                  />
                </Col>
                <Col md={12} className="fleetselection">
                  <div className="selectfleet">{'ESS'}</div>
                  <DropdownList
                    data={this.state.essList}
                    value={this.state.selectedESS}
                    className="dropdownfleetlist"
                    placeholder="Select ESS"
                    onChange={this.handleESSClick}
                    busy={this.props.loadingVehicles === true}
                    selectIcon={<CaretDown />}
                  />
                </Col>
                <Col md={12} className="fleetselection">
                  <div className="selectfleet">{'Pack'}</div>
                  <DropdownList
                    data={this.state.packList}
                    value={this.state.selectedPack}
                    className="dropdownfleetlist"
                    placeholder="Select Pack"
                    onChange={this.handlePackClick}
                    busy={this.props.loadingVehicles === true}
                    disabled={this.state.selectedPlatform !== '3'}
                    selectIcon={<CaretDown />}
                  />
                </Col>
                <Col md={12} className="fleetselection">
                  <div className="selectfleet">{'Report title'}</div>

                  <Form.Control
                    // required
                    // autoComplete="off"
                    type="text"
                    placeholder=""
                    value={this.state.reportName}
                    className="fleettitle"
                    onChange={this.handleReportNameEssClick}
                    onKeyPress={this.handleReportSpecialChar}
                  />
                </Col>
                <Col md={12} className="datePickerTextbox">
                  <div className="selectfleet">{'Report period'}</div>

                  <DateRangePicker
                    ref={this.datepickerRef}
                    onApply={this.commonDate_onApply}
                    // onCallback={this.commonDate_callback}
                    initialSettings={{
                      showDropdowns: true,
                      maxDate: Moment(
                        new Date(Moment().subtract(1, 'days').endOf('day'))
                      ).format('MM/DD/YYYY'),
                    }}>
                    <span className="timevalue">
                      <input
                        value={
                          Moment(this.state.startDate).format('DD.MM.YYYY') +
                          ' - ' +
                          Moment(this.state.endDate).format('DD.MM.YYYY')
                        }
                        className="datepickervalue"
                        readOnly
                      />
                    </span>
                  </DateRangePicker>
                  <div className="error-message-time">
                    {!isValid ? <FormattedMessage {...messages.problem} /> : ''}
                  </div>
                </Col>
                <button
                  onClick={this.generateWordReport}
                  type="button"
                  className="btn  btn-sm buttongenerate"
                  disabled={dataInValid || !isValid}>
                  Generate
                </button>

                <DropdownButton
                  id="buttondownload"
                  title="Download"
                  // type="button"
                  variant=""
                  className="btn  btn-sm buttondownload"
                  disabled={!this.enableDownloadBtn() || !isValid}
                  onClick={e => {
                    if (e.target.name === 'word') {
                      generateReport(
                        this.state.selectedFleet,
                        this.state.reportName,
                        this.state.selectedVehicle,
                        this.state.selectedESS,
                        Moment(this.state.startDate).format('DD.MM.YYYY'),
                        Moment(this.state.endDate).format('DD.MM.YYYY'),
                        vehicleCount,
                        this.state.essCount,
                        this.state.selectedPack,
                        this.state.packCount,
                        this.state.selectedPlatform
                      );
                    }
                  }}>
                  <Dropdown.Item name="word" eventKey="1" as="button">
                    Word
                  </Dropdown.Item>
                  {/* <Dropdown.Item name="excel" eventKey="2" as="button">
                    Excel
                  </Dropdown.Item> */}
                </DropdownButton>
                <button
                  onClick={this.emailWordReport}
                  type="button"
                  className="btn  btn-sm buttonemail"
                  disabled={!this.enableDownloadBtn() || !isValid}>
                  Send via email
                </button>
              </Tab>
            </Tabs>
          </Col>

          <Col md={9} className="">
            {this.state.selectedTab === 'fleetreport' ? (
              this.state.fleetReportOptions.radio_Btn === 'r_word' ? (
                this.props.loadingFleetWordReportData === true ? (
                  <>
                    <Col className="containerreportpreviewtitlebar">
                      <div className="reportview-menubar">
                        <div className="reportview-title">Report preview</div>
                      </div>
                    </Col>
                    <div className="generatedreportcontainer">
                      <Loading loadintText="Loading data" />
                    </div>
                  </>
                ) : this.props.fleetWordReportData !== null ? (
                  <FleetWordReport
                    fleetWordReportData={this.props.fleetWordReportData}
                  />
                ) : (
                  <>
                    <Col className="containerreportpreviewtitlebar">
                      <div className="reportview-menubar">
                        <div className="reportview-title">Report preview</div>
                      </div>
                    </Col>
                    <div className="generatedreportcontainer">
                      <Col md={12} className="addMarginTop previewscreen">
                        <div style={{ width: '272px' }}>
                          Report preview shall be displayed here..
                        </div>
                        {/* <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div> */}
                      </Col>
                    </div>
                  </>
                )
              ) : this.props.excelReportData !== null ? (
                <>
                  <Col className="containerreportpreviewtitlebar">
                    <div className="reportview-menubar">
                      <div className="reportview-title">Report preview</div>
                    </div>
                  </Col>
                  <div className="excelreportcontainer">
                    <ExcelReportPreview
                      loadingExcelReport={this.props.loadingExcelReport}
                      excelReportData={this.props.excelReportData}
                      selectedPlatform={this.state.selectedPlatformGenerate}
                    />
                  </div>
                </>
              ) : this.props.loadingExcelReport === true ? (
                <>
                  <Col className="containerreportpreviewtitlebar">
                    <div className="reportview-menubar">
                      <div className="reportview-title">Report preview</div>
                    </div>
                  </Col>
                  <div className="generatedreportcontainer">
                    <Loading loadintText="Loading data" />
                  </div>
                </>
              ) : (
                <>
                  <Col className="containerreportpreviewtitlebar">
                    <div className="reportview-menubar">
                      <div className="reportview-title">Report preview</div>
                    </div>
                  </Col>
                  <div className="generatedreportcontainer">
                    <Col md={12} className="addMarginTop previewscreen">
                      <div style={{ width: '272px' }}>
                        Report preview shall be displayed here..
                      </div>
                      {/* <div className="addMarginTop"></div> */}
                    </Col>
                  </div>
                </>
              )
            ) : this.props.vehiclesListData !== null &&
              this.props.batteryRelData !== null &&
              this.props.batteryStrData !== null &&
              this.props.operatingHoursHistogramData !== null &&
              this.props.temperatureHistogramData !== null &&
              this.props.usgSocHistogramData !== null &&
              this.props.usgPowHistogramData !== null &&
              this.props.usgDodHistogramData !== null ? (
              this.state.selectedPlatform === '3' ? (
                this.essPlatform3Preview()
              ) : (
                this.essPlatform1Preview()
              )
            ) : (
              <>
                <Col className="containerreportpreviewtitlebar">
                  <div className="reportview-menubar">
                    <div className="reportview-title">Report preview</div>
                  </div>
                </Col>
                <div className="generatedreportcontainer">
                  <Col md={12} className="addMarginTop previewscreen">
                    <div style={{ width: '272px' }}>
                      Report preview shall be displayed here..
                    </div>
                    {/* <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div> */}
                  </Col>
                </div>
              </>
            )}
          </Col>
        </Row>
      </React.Fragment>
    ) : (
      <Unauthorized />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  overviewData: overviewDataSelector(),
  loadingVehicles: loadingVehiclesSelector(),
  vehiclesListData: vehiclesListDataSelector(),
  usgPowHistogramData: usgPowHistogramDataSelector(),
  usgDodHistogramData: usgDodHistogramDataSelector(),
  batteryRelData: batteryRelDataSelector(),
  operatingHoursHistogramData: operatingHoursHistogramDataSelector(),
  temperatureHistogramData: temperatureHistogramDataSelector(),
  usgSocHistogramData: usgSocHistogramDataSelector(),
  batteryStrData: batteryStrDataSelector(),
  loadingEventList: loadingEventListSelector(),
  eventList: eventListSelector(),
  fleetsListData: fleetsListDataSelector(),
  loadingExcelReport: loadingExcelReportSelector(),
  excelReportData: excelReportDataSelector(),
  loadingExcelReportEmail: loadingExcelReportEmailSelector(),
  excelReportEmail: excelReportEmailSelector(),
  monitoredCountData: monitoredCountDataSelector(),
  loadingFleetWordReportData: loadingFleetWordReportSelector(),
  fleetWordReportData: fleetWordReportSelector(),
});

const mapDispatchToProps = {
  getFleetsList: fetchFleetsList,
  getVehiclesList: fetchVehiclesList,
  fetchUsgPowHistogramData,
  fetchUsgDodHistogramData,
  getBatteryRelData: fetchBatteryRelData,
  getBatteryStrData: fetchBatteryStrData,
  getOperatingHoursHistogramData: fetchOperatingHoursHistogramData,
  getTemperatureHistogramData: fetchTemperatureHistogramData,
  fetchUsgSocHistogramData,
  fetchEventList,
  clearChartData,
  fetchExcelReportData,
  fetchExcelReportEmailData,
  clearExcelReportData,
  fetchFleetWordReportData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportView)
);
