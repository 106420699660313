import React from 'react';
import nodatafound from '../../assets/images/abb_nodata_information.svg';

function NoDataFound() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        height: '500px',
        backgroundColor: '#f7f7f7',
      }}>
      <div style={{ display: 'flex', gap: '4px', alignContent: 'center' }}>
        <img
          src={nodatafound}
          alt="No data available"
          width={24}
          height={24}
          style={{ marginBottom: '4px' }}
        />
        <p style={{ fontSize: '14px' }}>
          No data available for selected vehicle during the selected time
          horizon ...
        </p>
      </div>
    </div>
  );
}

export default NoDataFound;
